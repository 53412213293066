<template>
	<card>
		<l-alert class="text-left" :type="message.type" v-if="message">{{ message.text }}</l-alert>
		<form-wizard shape="tab"
				error-color="#FB404B"
				color="#35495E"
				title="Create An Event"
				subtitle="Follow the flow below to create your event" v-if="event"
				@on-complete="addEvent"
				@on-change="scrollTop">
			<tab-content title="Details"
				class="col-12"
				:before-change="() => this.$refs.detailStep.validate()"
				icon="nc-icon nc-bullet-list-67">
				<create-event-details :initDate="date" ref="detailStep" />
			</tab-content>
			<tab-content title="Location"
						class="col-12"
						:before-change="() => this.$refs.locationStep.validate()"
						icon="nc-icon nc-pin-3">
				<create-event-location ref="locationStep" />
			</tab-content>
			<tab-content title="Access"
						class="col-12"
						icon="nc-icon nc-lock-circle-open">
				<create-event-access ref="accessStep" />
			</tab-content>
		</form-wizard>
	</card>
</template>

<script>
	import LAlert from '@/components/Alert';
	import { FormWizard, TabContent } from 'vue-form-wizard'
	import CreateEventDetails from '@/components/Wizards/CreateEventSteps/Details';
	import CreateEventLocation from '@/components/Wizards/CreateEventSteps/Location';
	import CreateEventAccess from '@/components/Wizards/CreateEventSteps/Access';
	import 'vue-form-wizard/dist/vue-form-wizard.min.css'
	export default {
		props: ['date'],
		components: {
			LAlert,
			FormWizard,
			TabContent,
			CreateEventDetails,
			CreateEventLocation,
			CreateEventAccess
		},
		data () {
			return {
				event: false,
				message: false
			}
		},
		computed: {
			Event () {
				return this.$FeathersVuex.api.Event;
			}
		},
		methods: {
			async addEvent () {
				const { title, description, dateTimePicker: [start, end], image } = this.$refs.detailStep.form;
				const { location } = this.$refs.locationStep.form;
				const { inviteType, invitees } = this.$refs.accessStep;
				const event = new this.Event({
					title,
					description,
					location,
					uploadId: image.response._id,
					start,
					end,
					inviteType,
					invitees
				});
				try {
					await event.save();
					this.$emit('saved');
					this.$refs.detailStep.reset();
					this.$refs.locationStep.reset();
					this.$refs.accessStep.reset();
				} catch (e) {
					this.message = {
						type: 'danger',
						text: e.message
					}
				}
			}
		},
		mounted () {
			this.event = new this.Event;
		}
	}
</script>

<style>
</style>
