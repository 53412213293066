<template>
	<mobile-preview @close="$emit('close')" v-if="previewEvent">
		<div class="container" style="overflow: scroll; padding-bottom: 100px;">
			<div class="row">
				<div class="col-md-12 p-0">
					<div 
						style="height: 350px; background-size: cover;"
						:style="{ backgroundImage: 'url(' + previewEvent.upload.files[0].signedURL + ')' }" 
					/>
				</div>
			</div>
			<div class="row">
				<div class="col-md-12">
					<h2 style="font-size: 28px; line-height: 42px; font-weight: bold; font-family: 'Sans Serif'">{{ previewEvent.title }}</h2>
				</div>
			</div>
			<div class="row">
				<div class="col-md-12">
					<div class="card p-3 mb-2" style="border-color: #E6EBF0; box-shadow: 0px 2px 3px rgba(0, 0, 0, .05);">
						<div class="row">
							<div class="col-2">
								<i class="nc-icon nc-time-alarm m-2" style="font-size: 25px; font-weight: bold;" />
							</div>
							<div class="col-10">
								<div class="row">
									<div class="col-md-12 font-weight-bold">
										{{ previewEvent.start | moment('MMMM DD') }} -
										{{ previewEvent.end | moment('MMMM DD') }}
									</div>
								</div>
								<div class="row">
									<div class="col-md-12">
										{{ previewEvent.start | moment('hh:ss a') }} -
										{{ previewEvent.end | moment('hh:ss a') }}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-md-12">
					<div class="card p-3 mb-2" style="border-color: #E6EBF0; box-shadow: 0px 2px 3px rgba(0, 0, 0, .05);">
						<div class="row">
							<div class="col-2">
								<i class="nc-icon nc-pin-3 m-2" style="font-size: 25px; font-weight: bold;" />
							</div>
							<div class="col-10">
								<div class="row">
									<div class="col-md-12 font-weight-bold">
										{{ previewEvent.location }}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-md-12">
					<div v-html="previewEvent.description" />
				</div>
			</div>
		</div>
		<div style="background: #fff; height: 90px; width: 100%; position: absolute; bottom: 0px; box-shadow: 0px -1px 5px rgba(0,0,0,.05); border: solid #E6EBF0;" class="p-3">
			<div class="row">
				<div class="col-4 pr-0 pt-2" style="font-size: 15px;">
					<b style="font-weight: bold;">Want to join?</b>
					<p class="text-muted" style="font-size: 15px; white-space: nowrap;">Seats still available</p>
				</div>
				<div class="col-8 text-right">
					<button class="btn btn-lg" style="border: none; background: #BFBB85; color: #413F21; font-weight: bold; font-family: arial;">RSVP Now</button>
				</div>
			</div>
		</div>
	</mobile-preview>
</template>
<script>
	import MobilePreview from '@/components/MobilePreview';
	export default {
		components: {
			MobilePreview
		},
		props: ['previewEvent'],
		data () {
			return {
				previewImage: false
			}
		},
		async mounted () {
			console.log(this.previewEvent.image);
			this.previewImage = await this.authImage(this.previewEvent.image);
			console.log(this.previewImage);
		}
	};
</script>
