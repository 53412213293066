<template>
	<div class="row">
		<div class="col-lg-12 col-md-12">
			<edit-event-form :event-id="$route.params.eventId" :key="$route.params.eventId" ref="form" />
		</div>
	</div>
</template>
<script>
import Swal from 'sweetalert2';
import EditEventForm from './Forms/EditEventForm.vue'

export default {
	components: {
		EditEventForm,
	},
	methods: {
		beforeUnload (e) {
			if (this.$refs.form.isDirty) {
				e.preventDefault();
				e.returnValue = '';
			}
		},
		async protectUnsaved () {
			if (this.$refs.form.isDirty) {
				const result = await Swal.fire({
					title: 'Something Changed...',
					text: `You are about to lose unsaved changes. Do you still want to leave this page?`,
					type: 'warning',
					showCancelButton: true,
					cancelButtonClass: 'btn btn-default btn-simple',
					confirmButtonText: 'Leave Now',
					confirmButtonClass: 'btn btn-warning btn-simple',
					buttonsStyling: false
				}).then(async result => {
					if (result.value) {
						return true;
					} else {
						return false;;
					}
				});
				return result;
			}
			return true;
		}
	},
	async beforeRouteLeave (to, from, next) {
		const allow = await this.protectUnsaved();
		if (allow) {
			next();
		} else {
			next(false);
		}
	},
	mounted () {
		window.addEventListener('beforeunload', this.beforeUnload);
	},
	beforeDestroy () {
		window.removeEventListener('beforeunload', this.beforeUnload);
	}
}
</script>
<style>

</style>
