<template>
	<div>
		<card class="mb-0" style="border-radius: 5px 5px 0px 0px;">
			<div class="d-flex justify-content-center justify-content-sm-between flex-wrap">
				<l-button @click.prevent="prev" type="primary">
					<i class="nc-icon nc-stre-left"></i>
				</l-button>
				<div class="btn-group">
					<button type="button" :class="{active: view == 'day'}" @click.prevent="changeView('timeGridDay'); view='day';" class="btn btn-primary btn-outline">Day</button>
					<button type="button" :class="{active: view == 'week'}" @click.prevent="changeView('timeGridWeek'); view='week';" class="btn btn-primary btn-outline">Week</button>
					<button type="button" :class="{active: view == 'month'}" @click.prevent="changeView('dayGridMonth'); view='month';" class="btn btn-primary btn-outline">Month</button>
				</div>
				<l-button @click.prevent="next" type="primary">
					<i class="nc-icon nc-stre-right"></i>
				</l-button>
			</div>
		</card>
		<div class="card card-calendar" style="border-radius: 0px 0px 5px 5px; border-top: none;">
			<div class="card-body">
				<fullCalendar
					v-loading="areEventsLoading"
					ref="calendar"
					:options="calendarOptions" />
			</div>
		</div>
	</div>
</template>
<script>
	import { mapState, mapGetters } from 'vuex';
	import FullCalendar from "@fullcalendar/vue";
	import dayGridPlugin from "@fullcalendar/daygrid";
	import timeGridPlugin from "@fullcalendar/timegrid";
	import listPlugin from '@fullcalendar/list';
	import interactionPlugin from "@fullcalendar/interaction";
	export default {
		components: {
			FullCalendar
		},
		props: ['events'],
		data () {
			return {
				view: 'month',
				calendarOptions: {
					plugins: [
						dayGridPlugin,
						timeGridPlugin,
						interactionPlugin,
						listPlugin
					],
					headerToolbar: {
						start: "",
						center: "title",
						right: "",
					},
					initialView: "dayGridMonth",
					editable: true,
					selectable: true,
					select: this.handleDateSelect,
					eventClick: this.handleEventClick,
					eventDrop: this.handleEventChange,
					eventResize: this.handleEventChange,
					events: [],
					eventColor: '#447df7 !important',
				},
			}
		},
		computed: {
			...mapState('events', { areEventsLoading: 'isFindPending' }),
			Event () {
				return this.$FeathersVuex.api.Event;
			}
		},
		methods: {
			start () {
				return this.$refs.calendar.getApi().view.currentStart;
			},
			end () {
				return this.$refs.calendar.getApi().view.currentEnd;
			},
			prev () {
				this.$refs.calendar.getApi().prev();
				this.emitChange();
			},
			next () {
				this.$refs.calendar.getApi().next();
				this.emitChange();
			},
			changeView(view) {
				this.$refs.calendar.getApi().changeView(view);
				this.emitChange();
			},
			async handleEventChange (info) {
				try {
					const { start, end, extendedProps: { _id } } = info.event;
					const event = new this.Event({
						_id,
						start,
						end
					});
					await event.save();
					this.$notify({
						title: 'Event updated!',
						icon: 'nc-icon nc-check-2',
						horizontalAlign: 'right',
						verticalAlign: 'bottom',
						type: 'success'
					});
				} catch (e) {
					this.$notify({
						title: e.message,
						icon: 'nc-icon nc-key-25',
						horizontalAlign: 'right',
						verticalAlign: 'bottom',
						type: 'danger'
					});
				}
			},
			handleDateSelect(selectInfo) {
				this.$emit('date-selected', selectInfo);
			},
			handleEventClick(clickInfo) {
				this.$router.push({ name: 'events-edit', params: { eventId: clickInfo.event.extendedProps._id } });
			},
			emitChange () {
				this.$emit('change', { start: this.start(), end: this.end() });
			}
		},
		watch: {
			events: function(val) {
				this.calendarOptions.events = val.data;
			}
		},
		mounted() {
			this.emitChange();
		}
	}
</script>
<style>
#fullCalendar {
  min-height: 400px;
}
.fc-event {
    background: #447df7 !important;
    border: none;
}
.fc-event-past {
    background: #ccc !important;
}
.fc-list-event-title {
    width: 100% !important;
    text-align: left !important;
}
tr.fc-list-event {
    opacity: .8 !important;
    color: #000 !important;
    font-weight: bold;
}
.fc-list-event :hover {
    background: inherit !important;
    opacity: 1;
}

.fc-highlight {
    background-color: #86e6f9 !important;
}
</style>
