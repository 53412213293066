import feathersClient, { makeServicePlugin, BaseModel } from '../../feathers-client'

class Group extends BaseModel {
  constructor(data, options) {
    super(data, options)
  }
  // Define default properties here
  static instanceDefaults() {
    return {
      name: null,
      ownerId: null,
      members: [],
      private: false,
      leavable: true
    }
  }
}
Group.modelName = 'Group';
const servicePath = 'groups'
const servicePlugin = makeServicePlugin({
  Model: Group,
  service: feathersClient.service(servicePath),
  servicePath
})

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
})

export default servicePlugin
