<template>
  <card v-if="member">
    <div slot="header">
      <div class="alert alert-info d-flex justify-content-center justify-content-sm-between" v-if="(member.isDeleted || member.isSuspended) && moderator._id">
          <div>
            <router-link class="btn-sm btn-info mr-2" :to="'/members/' + moderator._id"
                v-tooltip.top-center="'Audit the moderator'">
                <i class="fa fa-id-badge"></i>
                {{ moderator.firstName }} {{ moderator.lastName }}
            </router-link> 
            <b v-if="member.isDeleted">deleted</b><b v-else>suspended</b>
            {{ member.firstName }} {{ member.lastName }}
          </div>
          <div style="font-weight: bold;">{{ moderationDate | moment('calendar') }}</div>
      </div>
      <div class="d-flex justify-content-between">
        <div class="w-50">
            <h4 class="title mt-0 mb-1">{{ member.firstName }} {{ member.lastName }}</h4>
            <h6 class="text-small mb-3">Joined {{ member.createdAt | moment("from") }}</h6>
            <h6 class="subtitle mb-5" style="font-weight: 300;">{{ member.about }}</h6>
        </div>
        <div class="w-50 text-right">
            <div class="btn-group" style="margin-top: 4px;">
                <button class="btn btn-social btn-reddit" @click.prevent="hubspotAction">
                    <i class="fa" slot="on" style="fill: #fff;">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M267.4 211.6c-25.1 23.7-40.8 57.3-40.8 94.6 0 29.3 9.7 56.3 26 78L203.1 434c-4.4-1.6-9.1-2.5-14-2.5-10.8 0-20.9 4.2-28.5 11.8-7.6 7.6-11.8 17.8-11.8 28.6s4.2 20.9 11.8 28.5c7.6 7.6 17.8 11.6 28.5 11.6 10.8 0 20.9-3.9 28.6-11.6 7.6-7.6 11.8-17.8 11.8-28.5 0-4.2-.6-8.2-1.9-12.1l50-50.2c22 16.9 49.4 26.9 79.3 26.9 71.9 0 130-58.3 130-130.2 0-65.2-47.7-119.2-110.2-128.7V116c17.5-7.4 28.2-23.8 28.2-42.9 0-26.1-20.9-47.9-47-47.9S311.2 47 311.2 73.1c0 19.1 10.7 35.5 28.2 42.9v61.2c-15.2 2.1-29.6 6.7-42.7 13.6-27.6-20.9-117.5-85.7-168.9-124.8 1.2-4.4 2-9 2-13.8C129.8 23.4 106.3 0 77.4 0 48.6 0 25.2 23.4 25.2 52.2c0 28.9 23.4 52.3 52.2 52.3 9.8 0 18.9-2.9 26.8-7.6l163.2 114.7zm89.5 163.6c-38.1 0-69-30.9-69-69s30.9-69 69-69 69 30.9 69 69-30.9 69-69 69z"/></svg></i>
                    <span class="ml-2" v-if="!member.hubspotId">Link Hubspot</span>
                    <span class="ml-2" v-else>View Contact</span>
                </button>
                <button v-if="member.hubspotId" class="btn btn-danger btn-outline" v-tooltip.top-center="'Unlink Hubspot'" @click.prevent="member.hubspotId = null; updateMember()"><i class="fa fa-times"></i></button>
            </div>
            <el-button
                :loading="formLoading"
                :disabled="!isDirty"
                type="success"
                @click.prevent="updateMember" style="margin-left: 5px;">
                <i class="fa fa-save"></i> Update Member
            </el-button>
            <p v-if="isDirty" class="text-muted" style="font-size: 10px;">Something changed... Don't forget to save!</p>
        </div>
      </div>
      <div class="d-flex justify-content-between border-bottom mb-3 pb-2">
          <label>Approved</label>
          <l-switch v-model="form.approved" class="ml-2" color="green" @change="updateMember">
            <i class="fa fa-check" slot="on"></i>
            <i class="fa fa-times" slot="off"></i>
          </l-switch>
      </div>
      <div class="d-flex justify-content-between border-bottom mb-3 pb-2">
          <label>Email Verified</label>
          <l-switch v-model="form.isVerified" class="ml-2" @change="updateMember">
            <i class="fa fa-envelope" slot="on"></i>
            <i class="fa fa-times" slot="off"></i>
          </l-switch>
      </div>
      <div class="d-flex justify-content-between border-bottom pb-2">
          <label>Accepted Terms</label>
          <l-switch readonly v-model="form.acceptedTerms" color="purple" class="ml-2" @change="updateMember">
            <i class="fa fa-file-contract" slot="on"></i>
            <i class="fa fa-times" slot="off"></i>
          </l-switch>
      </div>
    </div>
    <div>
      <form>
        <div class="row">
          <div class="col-md-12">
            <fg-input type="email"
                      label="Email"
                      placeholder="Email"
                      v-model="form.email">
            </fg-input>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <fg-input type="text"
                      label="First Name"
                      placeholder="First Name"
                      v-model="form.firstName" readonly>
            </fg-input>
          </div>
          <div class="col-md-6">
            <fg-input type="text"
                      label="Last Name"
                      placeholder="Last Name"
                      v-model="form.lastName" readonly>
            </fg-input>
          </div>
        </div>
        
        <div class="row">
          <div class="col-md-6">
            <fg-input type="text"
                      label="HubSpot ID"
                      placeholder="HubSpot ID"
                      v-model="form.hubspotId" readonly>
            </fg-input>
          </div>
          <div class="col-md-6">
            <label>Roles</label>
            <el-select multiple class="w-100 select-default"
                       size="large"
                       v-model="form.roles"
                       placeholder="Multiple Select"
                       @change="updateMember"
                       >
              <el-option v-for="option in ['admin', 'moderator', 'user']"
                         class="select-default"
                         :value="option"
                         :label="option"
                         :key="option">
              </el-option>
            </el-select>
          </div>
        </div>

        <div class="text-right">
        </div>
        <DangerZone class="text-center">
            <button
                class="btn btn-warning btn-fill btn-wd mr-3"
                @click.prevent="suspend"
                v-if="!member.isDeleted && !member.isSuspended">
                <i class="fa fa-ban"></i> Suspend Member
            </button>
            <button
                class="btn btn-danger btn-outline btn-wd"
                @click.prevent="remove"
                v-if="!member.isDeleted && !member.isSuspended">
                <i class="fa fa-warning"></i> Delete Member
            </button>
            <button
                class="btn btn-success btn-fill btn-wd"
                @click.prevent="restore"
                v-if="member.isDeleted || member.isSuspended">
                <i class="fa fa-play"></i> Restore Member
            </button>
        </DangerZone>
        <div class="clearfix"></div>
      </form>
    </div>
    <el-dialog title="Link HubSpot ID" :visible.sync="dialogFormVisible">
      <form @submit.prevent="updateMember(); dialogFormVisible=false;">
          <div>
            <fg-input label="HubSpot ID"
                      type="text"
                      v-model="form.hubspotId"
                      placeholder="Enter users HubSpot ID">
            </fg-input>
            <l-alert class="text-left" :type="message.type" v-if="message">{{ message.text }}</l-alert>
            <button type="submit" class="btn btn-fill btn-success">Submit</button>
          </div>
        </form>
    </el-dialog>
  </card>
</template>
<script>
	import moment from 'moment';
	import Swal from 'sweetalert2';
	import DangerZone from '@/components/DangerZone';
	import { Switch as LSwitch } from '@/components/index';
	import { Button, Dialog, Select, Option } from 'element-ui';
	export default {
		props: ['memberId'],
		components: {
			DangerZone,
			LSwitch,
			[Button.name]: Button,
			[Dialog.name]: Dialog,
			[Select.name]: Select,
			[Option.name]: Option
		},
		data () {
			return {
				dangerZone: false,
				dialogFormVisible: false,
				member: false,
				form: false,
				message: false,
				moderator: {},
				moderationDate: new Date
			}
		},
		computed: {
			User () {
				return this.$FeathersVuex.api.User;
			},
			isDirty () {
				return JSON.stringify(this.member) !== JSON.stringify(this.form);
			}
		},
		methods: {
			remove () {
				Swal.fire({
					title: 'Are you sure?',
					text: `${this.member.firstName} won't be able to use the app once you delete them...`,
					type: 'warning',
					showCancelButton: true,
					confirmButtonClass: 'btn btn-danger btn-fill',
					cancelButtonClass: 'btn btn-default btn-simple',
					confirmButtonText: 'Delete ' + this.member.firstName + ' ' + this.member.lastName,
					buttonsStyling: false
				}).then(async result => {
					if (result.isConfirmed) {
						try {
							await this.member.remove();
							Swal.fire({
								title: 'Deleted!',
								text: `${this.member.firstName} was deleted`,
								type: 'success',
								confirmButtonClass: 'btn btn-success btn-fill',
								buttonsStyling: false
							})
						} catch(e) {
							Swal.fire({
								title: 'Error!',
								text: `Sorry we couldn't delete ${this.member.firstName} right now. If the problem continues please contact an administrator.`,
								type: 'error',
								confirmButtonClass: 'btn btn-danger btn-fill',
								buttonsStyling: false,
								confirmButtonText: 'Dismiss'
							});
						}
					}
				})
			},
			suspend () {
				Swal.fire({
					title: 'Are you sure?',
					text: `${this.member.firstName} won't be able to use the app once you suspend them... However, their content will still be visible.`,
					type: 'warning',
					showCancelButton: true,
					confirmButtonClass: 'btn btn-warning btn-fill',
					cancelButtonClass: 'btn btn-default btn-simple',
					confirmButtonText: 'Suspend ' + this.member.firstName + ' ' + this.member.lastName,
					buttonsStyling: false
				  }).then(async result => {
					if (result.isConfirmed) {
						try {
							this.member.isSuspended = {
								at: new Date,
								by: this.user._id
							};
							await this.member.save();
							Swal.fire({
								title: 'Suspended!',
								text: `${this.member.firstName} was suspended`,
								type: 'success',
								confirmButtonClass: 'btn btn-success btn-fill',
								buttonsStyling: false
							})
						} catch(e) {
							Swal.fire({
								title: 'Error!',
								text: `Sorry we couldn't suspend ${this.member.firstName} right now. If the problem continues please contact an administrator.`,
								type: 'error',
								confirmButtonClass: 'btn btn-danger btn-fill',
								buttonsStyling: false,
								confirmButtonText: 'Dismiss'
							});
						}
					}
				})
			},
			restore () {
				Swal.fire({
					title: 'Are you sure?',
					text: `${this.member.firstName} will be able to use the app normally again.`,
					type: 'warning',
					showCancelButton: true,
					confirmButtonClass: 'btn btn-success btn-fill',
					cancelButtonClass: 'btn btn-default btn-simple',
					confirmButtonText: 'Restore ' + this.member.firstName + ' ' + this.member.lastName,
					buttonsStyling: false
				  }).then(async result => {
					if (result.isConfirmed) {
						try {
							this.member.isSuspended = null;
							this.member.isDeleted = null;
							await this.member.save();
							Swal.fire({
								title: 'Restored!',
								text: `${this.member.firstName} was restored`,
								type: 'success',
								confirmButtonClass: 'btn btn-success btn-fill',
								buttonsStyling: false
							})
						} catch(e) {
							Swal.fire({
								title: 'Error!',
								text: `Sorry we couldn't restore ${this.member.firstName} right now. If the problem continues please contact an administrator.`,
								type: 'error',
								confirmButtonClass: 'btn btn-danger btn-fill',
								buttonsStyling: false,
								confirmButtonText: 'Dismiss'
							});
						}
					}
				})
			},
			hubspotAction () {
				if (this.member.hubspotId) {
					window.open('https://app.hubspot.com/contacts/20785234/contact/' + this.member.hubspotId, '_blank');
				} else {
					this.dialogFormVisible = true;
				}
			},
			async updateMember () {
				try {
					this.form.commit();
					await this.member.save();
					this.form = this.member.clone();
					this.$notify({
						title: 'Member updated!',
						icon: 'nc-icon nc-check-2',
						horizontalAlign: 'right',
						verticalAlign: 'bottom',
						type: 'success'
					});
				} catch (e) {
					this.$notify({
						title: e.message,
						icon: 'nc-icon nc-key-25',
						horizontalAlign: 'right',
						verticalAlign: 'bottom',
						type: 'danger'
					});
				}
			}
		},
		async mounted () {
			this.member = await this.User.get(this.memberId, { query: { $showSoftDeletes: true  }});
			this.form = this.member.clone();
			if (this.member.isDeleted) {
				this.moderator = await this.User.get(this.member.isDeleted.by);
				this.moderationDate = this.member.isDeleted.at;
			}
			if (this.member.isSuspended) {
				this.moderator = await this.User.get(this.member.isSuspended.by);
				this.moderationDate = this.member.isSuspended.at;
			}
		}
	}

</script>
<style>

</style>
