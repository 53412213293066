<template>
  <nav class="navbar navbar-expand-lg ">
    <div class="container-fluid">
      <div class="navbar-minimize">
        <button class="btn btn-outline btn-fill btn-round btn-icon d-none d-lg-block" @click="minimizeSidebar">
          <i :class="$sidebar.isMinimized ? 'fa fa-ellipsis-v' : 'fa fa-navicon'"></i>
        </button>
      </div>
      <a class="navbar-brand">{{this.$route.meta.seo.title}}</a>
      <button type="button"
              class="navbar-toggler navbar-toggler-right"
              :class="{toggled: $sidebar.showSidebar}"
              aria-expanded="false"
              @click="toggleSidebar">
        <span class="navbar-toggler-bar burger-lines"></span>
        <span class="navbar-toggler-bar burger-lines"></span>
        <span class="navbar-toggler-bar burger-lines"></span>
      </button>
      <div class="collapse navbar-collapse justify-content-end">
        <ul class="navbar-nav">
          <drop-down position="right">
            <i slot="title" class="nc-icon nc-bullet-list-67"></i>

            <b class="dropdown-item" style="font-weight: bold;">
              <i class="nc-icon nc-circle-09"></i> {{ user.firstName }} {{ user.lastName }}
            </b>
            <a href="#" @click.prevent="broadcastFormVisible = true;" class="dropdown-item">
              <i class="nc-icon nc-send"></i> Broadcast 
            </a>
            <div class="divider"></div>
            <a href="#" @click.prevent="logout" class="dropdown-item text-danger">
              <i class="nc-icon nc-button-power"></i> Log out
            </a>

          </drop-down>
        </ul>
      </div>
      <el-dialog title="Send A Broadcast" :visible.sync="broadcastFormVisible">
          <form @submit.prevent="addBroadcast" ref="broadcastForm">
            <fg-input label="Title" type="text" placeholder="Notificaton Title" v-model="broadcast.title" />
            <div class="form-group has--label">
                <label>Body</label>
                <textarea class="form-control" label="Description" type="text" placeholder="Notificaton Body Text" v-model="broadcast.body" />
            </div>
            <button type="submit" class="btn btn-fill btn-success">Send</button>
          </form>
      </el-dialog>
    </div>
  </nav>
</template>
<script>
  import { Dialog } from 'element-ui';
  export default {
    components: {
      [Dialog.name]: Dialog
    },
    computed: {
      routeName () {
        const {name} = this.$route
        return this.capitalizeFirstLetter(name)
      },
      Broadcast () {
        return this.$FeathersVuex.api.Broadcast;
      }
    },
    data () {
      return {
        activeNotifications: false,
        broadcastFormVisible: false,
        broadcast: {
            title: '',
            body: ''
        }
      }
    },
    methods: {
      async addBroadcast () {
        const broadcast = new this.Broadcast(this.broadcast);
        await broadcast.save();
        this.$refs.broadcastForm.reset();
        this.broadcastFormVisible = false;
      },
      capitalizeFirstLetter (string) {
        return string.charAt(0).toUpperCase() + string.slice(1)
      },
      toggleNotificationDropDown () {
        this.activeNotifications = !this.activeNotifications
      },
      closeDropDown () {
        this.activeNotifications = false
      },
      toggleSidebar () {
        this.$sidebar.displaySidebar(!this.$sidebar.showSidebar)
      },
      hideSidebar () {
        this.$sidebar.displaySidebar(false)
      },
      minimizeSidebar () {
        this.$sidebar.toggleMinimize()
      }
    }
  }

</script>
<style>

</style>
