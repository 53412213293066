<template>
	<div class="row d-flex justify-content-center align-items-center w-100">
	  <div class="col-lg-4 col-md-6 col-sm-8">
		<ValidationObserver v-slot="{ handleSubmit }" ref="observer" v-if="form && key">
		  <!--You can specify transitions on initial render. The `card-hidden` class will be present initially and then it will be removed-->
		  <form @submit.prevent="handleSubmit(submit)" v-loading="saving">
			<fade-render-transition>
			  <card>
				<div slot="header">
				  <h3 class="card-title text-center">Reset Password</h3>
				</div>
				<div>
				  <ValidationProvider
					name="password"
					rules="required|min:4"
					v-slot="{ passed, failed }"
				  >
					<fg-input  type="password"
							   vid="password"
							   :error="failed ? 'The Password field is required': null"
							   :hasSuccess="passed"
							   label="Password"
							   name="password"
							   v-model="form.password">
					</fg-input>
				  </ValidationProvider>
				  <ValidationProvider
					name="confirm-password"
					rules="required|confirmed:password"
					v-slot="{ passed, failed }"
				  >
					<fg-input  type="password"
							   :error="failed ? 'Your password doesn\'t match': null"
							   :hasSuccess="passed"
							   name="confirm-password"
							   label="Confirm Password"
							   v-model="confirmPassword">
					</fg-input>
				  </ValidationProvider>
				</div>
				<div class="text-center mt-5">
				  <l-alert class="text-left" :type="message.type" v-if="message">{{ message.text }}</l-alert>
				  <button type="submit" class="btn btn-fill btn-success btn-round btn-wd ">Update Password</button>
				</div>
			  </card>
			</fade-render-transition>
		  </form>
		</ValidationObserver>
		<card v-else v-loading="saving">
			<div slot="header">
			  <h3 class="card-title text-center">Reset Password</h3>
			</div>
			<l-alert type="danger"><b>Invalid Key</b><br /> We couldn't find the password reset record you're looking for. You can start the process again by entering your email below...</l-alert>
			<fg-input type="email" v-model="email" label="Email Address" placeholder="Email Address" />
			<div class="text-center mt-5">
			  <l-alert class="text-left" :type="message.type" v-if="message">{{ message.text }}</l-alert>
			  <button type="submit" @click.prevent="resend" class="btn btn-fill btn-info btn-round btn-wd" :disabled="!email">Reset Password</button>
			</div>
			
		</card>
	  </div>
	</div>
</template>
<script>
	import LAlert from '@/components/Alert';
	import { FadeRenderTransition } from '@/components/index'
	import { extend } from "vee-validate";
	import { required, email, min, confirmed } from "vee-validate/dist/rules";

	extend("email", email);
	extend("required", required);
	extend("confirmed", confirmed);
	extend("min", min);

	export default {
		components: {
			FadeRenderTransition,
			LAlert
		},
		computed: {
			ForgotPassword () {
				return this.$FeathersVuex.api.ForgotPassword;
			},
			key () {
				return this.$route.params.key;
			}
		},
		data() {
			return {
				confirmPassword: null,
				form: {},
				email: null,
				message: false,
				saving: false
			};
		},
		methods: {
			async submit() {
				this.saving = true;
				try {
					await this.form.update();
					this.message = {
						type: 'success',
						text: "Your password was reset!"
					};
					this.$refs.observer.reset();
					this.form = {};
					this.confirmPassword = null;
					this.$emit('done');
				} catch (e) {
					this.message = {
						type: 'danger',
						text: e.message
					};
				}
				this.saving = false;
			},
			async resend () {
				this.saving = true;
				try {
					await (new this.ForgotPassword({ email: this.email })).create();
					this.message = {
						type: 'success',
						text: "We've sent another password reset email. Please check your inbox now to finish resetting your password..."
					}
				} catch (e) {
					this.message = {
						type: 'danger',
						text: e.message
					};
				}
				this.saving = false;
			}
		},
		async mounted () {
			if (this.key) {
				try {
					this.form = await this.ForgotPassword.find({ query: { key: this.key } }).then(res => {
						if (res.total > 0) {
							return res.data[0];
						}
						return false;
					});
				} catch (e) {
					this.form = false;
				}
			}
		}
	}
</script>
<style>
  .navbar-nav .nav-item p{
    line-height: inherit;
    margin-left: 5px;
  }
</style>
