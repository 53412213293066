<template>
  <div class="row">
    <div class="col-md-12">
      <h4 class="title">Manage Members</h4>
    </div>

    <div class="col-12">
      <card>
        <div>
          <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
            <el-select
              class="select-default mb-3"
              style="width: 200px"
              v-model="pagination.perPage"
              placeholder="Per page">
              <el-option
                class="select-default"
                v-for="item in pagination.perPageOptions"
                :key="item"
                :label="item"
                :value="item">
              </el-option>
            </el-select>
            <el-input 
                      class="mb-3"
                      style="width: 200px"
                      placeholder="Search records"
                      v-model="searchQuery"
                      @change="runSearch"
                      :clearable="true"
                      :debounce="500"
                      aria-controls="datatables"/>
          </div>
          <div class="col-sm-12">
            <el-table stripe
                      v-loading="loading"
                      style="width: 100%;"
                      :data="members.data"
                      :lazy="true"
                      :row-class-name="tableRowClassName"
                      :default-sort="{prop: 'email', order: 'ascending'}"
                      @sort-change="sortResults"
                      border>
              <el-table-column min-width="100" prop="firstName" label="First Name" sortable />
              <el-table-column min-width="100" prop="lastName" label="Last Name" sortable />
              <el-table-column min-width="100" prop="email" label="Email" sortable />
              <el-table-column width="300" label="Roles">
                <template slot-scope="scope">
                  <el-select multiple class="select-warning w-100"
                           size="large"
                           v-model="scope.row.roles"
                           placeholder="Multiple Select"
                           @change="updateMember(scope.row)"
                           >
                  <el-option v-for="option in ['admin', 'moderator', 'user']"
                             class="select-warning"
                             :value="option"
                             :label="option"
                             :key="option">
                  </el-option>
                </el-select>
                </template>
              </el-table-column>
              <el-table-column width="120" label="Signed Up" prop="createdAt" sortable>
                <template slot-scope="scope">
                    {{ scope.row.createdAt | moment('from') }}
                </template>
              </el-table-column>
              <el-table-column width="120" label="Verification">
                <template slot-scope="scope">
                  <i v-tooltip.top-center="'Email Verified'" class="nc-icon nc-email-83 mr-1" v-if="scope.row.isVerified || scope.row.isOauthVerified"></i>
                  <i v-tooltip.top-center="'Access Approved'" class="nc-icon nc-lock-circle-open mr-1" v-if="scope.row.approved"></i>
                  <i v-tooltip.top-center="'HubSpot Connected'" class="nc-icon nc-app mr-1" v-if="scope.row.hubspotId !== null"></i>
                </template>
              </el-table-column>
              <el-table-column
                width="190"
                fixed="right">
                <template slot-scope="props">
                  <div class="btn-group">
                  <a v-tooltip.top-center="'Add To Group'" class="btn-success btn-sm btn"
                     @click="addToGroup(props.$index, props.row)"><i
                    class="fa fa-layer-group"></i></a>
                  <a v-tooltip.top-center="'Edit'" class="btn-info btn-sm btn"
                     @click="handleEdit(props.$index, props.row)"><i
                    class="fa fa-edit"></i></a>
                  <a v-tooltip.top-center="'Suspend'" class="btn-warning btn-sm btn"
                     @click="handleSuspend(props.$index, props.row)" v-if="!props.row.isDeleted && !props.row.isSuspended">
                    <i class="fa fa-pause"></i></a>
                  <a v-tooltip.top-center="'Delete'" class="btn-danger btn-sm btn"
                     @click="handleDelete(props.$index, props.row)" v-if="!props.row.isDeleted && !props.row.isSuspended"><i class="fa fa-times"></i></a>
                  <a v-tooltip.top-center="'Restore'" class="btn-success btn-sm btn"
                     @click="handleRestore(props.$index, props.row)" v-if="props.row.isDeleted || props.row.isSuspended"><i class="fa fa-play"></i></a>
                    </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
          <div class="">
            <p class="card-category">Showing {{from + 1}} to {{to}} of {{total}} entries</p>
          </div>
          <l-pagination class="pagination-no-border"
                        v-model="pagination.currentPage"
                        :per-page="pagination.perPage"
                        :total="members.total">
          </l-pagination>
        </div>
      </card>
    </div>
    <el-dialog title="Add To Group" :visible.sync="addUserToGroup">
        <form @submit.prevent>
              <el-select multiple class="select-default w-100"
                       size="large"
                       v-model="inspectedUser.groups"
                       placeholder="Multiple Select"
                       @change="updateMemberships(inspectedUser)"
                       >
              <el-option v-for="group in groups"
                         class="select-default"
                         :value="group._id"
                         :label="group.name"
                         :key="group._id">
              </el-option>
            </el-select>
        </form>
    </el-dialog>
  </div>
</template>
<script>
  import Swal from 'sweetalert2';
  import { mapActions } from 'vuex';
  import { Dialog, Table, TableColumn, Select, Option } from 'element-ui'
  import {Pagination as LPagination} from '@/components/index'
  import Fuse from 'fuse.js'

  export default {
    components: {
      LPagination,
      [Dialog.name]: Dialog,
      [Select.name]: Select,
      [Option.name]: Option,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn
    },
    data () {
      return {
        loading: true,
        addUserToGroup: false,
        inspectedUser: false,
        pagination: {
          perPage: 5,
          currentPage: 1,
          perPageOptions: [5, 10, 25, 50],
          total: 0
        },
        searchQuery: '',
        sort: {
            email: 1
        },
        members: {
            data: []
        },
        groups: []
      }
    },
    computed: {
      pagedData () {
        return this.members.data.slice(this.from, this.to)
      },
      to () {
        let highBound = this.from + this.pagination.perPage
        if (this.total < highBound) {
          highBound = this.total
        }
        return highBound
      },
      from () {
        return this.pagination.perPage * (this.pagination.currentPage - 1)
      },
      total () {
        return this.members.total
      },
      Group () {
        return this.$FeathersVuex.api.Group;
      }
    },
    methods: {
        async updateMemberships (member) {
            const userGroups = await this.Group
            .find({ query: { members: member._id } }).then(res => {
                return res.data.map(g => g._id);
            });
            const addGroups = member.groups.filter(g => !userGroups.includes(g));
            const removeGroups = userGroups.filter(g => !member.groups.includes(g));
            for (let group of addGroups) {
                this.$store.dispatch('groups/update', [group, { memberId: member._id }]).then(console.log);
            }
            for (let group of removeGroups) {
                this.$store.dispatch('groups/update', [group, { memberId: member._id, leave: true }]).then(console.log);
            }
        },
        sortResults (sort) {
            this.sort = { email: 1 };
            if (sort.order) {
                const newSort = {}
                newSort[sort.prop] = sort.order == 'ascending' ? 1 : -1;
                this.sort = newSort;
            }
            this.findMembers();
        },
        tableRowClassName ({row, rowIndex}) {
            if (row.isDeleted) {
                return 'deleted-row';
            }
            if (row.isSuspended) {
                return 'suspended-row';
            }
        },
      runSearch (query) {
          this.findMembers();
      },
      async findMembers () {
          this.loading = true;
          this.members = 
            await this.findUsers({ 
                query: {
                    $showSoftDeletes: true, 
                    $sort: this.sort,
                    $limit: this.pagination.perPage,
                    $skip: this.pagination.perPage * (this.pagination.currentPage - 1),
                    $or: [
                        {
                            email: {
                                $regex: this.searchQuery.split(' ').join('|'),
                                $options: 'i'
                            }
                        },
                        {
                            firstName: {
                                $regex: this.searchQuery.split(' ').join('|'),
                                $options: 'i'
                            }
                        },
                        {
                            lastName: {
                                $regex: this.searchQuery.split(' ').join('|'),
                                $options: 'i'
                            }
                        }
                    ]
                } 
            });
          this.loading = false;
      },
      async updateMember(member) {
        try {
        await member.save();
        this.$notify(
          {
            title: 'Member updated!',
            icon: 'nc-icon nc-check-2',
            horizontalAlign: 'right',
            verticalAlign: 'bottom',
            type: 'success'
          });
        } catch (e) {
          this.$notify(
          {
            title: e.message,
            icon: 'nc-icon nc-key-25',
            horizontalAlign: 'right',
            verticalAlign: 'bottom',
            type: 'danger'
          });
        }
      },
      handleLike (index, row) {
        alert(`Your want to like ${row.name}`)
      },
      handleEdit (index, row) {
          this.$router.push('/members/' + row._id);
      },
      addToGroup (index, row) {
          this.addUserToGroup = true;
          this.inspectedUser = row;
      },
      handleSuspend (index, row) {
        Swal.fire({
            title: 'Are you sure?',
            text: `${row.firstName} won't be able to use the app once you suspend them... However, their content will still be visible.`,
            type: 'warning',
            showCancelButton: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            cancelButtonClass: 'btn btn-default btn-simple',
            confirmButtonText: 'Suspend ' + row.firstName + ' ' + row.lastName,
            buttonsStyling: false
          }).then(async result => {
            if (result.isConfirmed) {
                try {
                    row.isSuspended = {
                        at: new Date,
                        by: this.user._id
                    };
                    await row.save();
                    Swal.fire({
                        title: 'Suspended!',
                        text: `${row.firstName} was suspended`,
                        type: 'success',
                        confirmButtonClass: 'btn btn-success btn-fill',
                        buttonsStyling: false
                    })
                } catch(e) {
                    Swal.fire({
                        title: 'Error!',
                        text: `Sorry we couldn't suspend ${row.firstName} right now. If the problem continues please contact an administrator.`,
                        type: 'error',
                        confirmButtonClass: 'btn btn-danger btn-fill',
                        buttonsStyling: false,
                        confirmButtonText: 'Dismiss'
                    });
                }
            }
          })
      },
      handleDelete (index, row) {
        Swal.fire({
            title: 'Are you sure?',
            text: `${row.firstName} won't be able to use the app once you delete them...`,
            type: 'warning',
            showCancelButton: true,
            confirmButtonClass: 'btn btn-danger btn-fill',
            cancelButtonClass: 'btn btn-default btn-simple',
            confirmButtonText: 'Delete ' + row.firstName + ' ' + row.lastName,
            buttonsStyling: false
          }).then(async result => {
            if (result.isConfirmed) {
                try {
                    await row.remove();
                    Swal.fire({
                        title: 'Deleted!',
                        text: `${row.firstName} was deleted`,
                        type: 'success',
                        confirmButtonClass: 'btn btn-success btn-fill',
                        buttonsStyling: false
                    })
                } catch(e) {
                    Swal.fire({
                        title: 'Error!',
                        text: `Sorry we couldn't delete ${row.firstName} right now. If the problem continues please contact an administrator.`,
                        type: 'error',
                        confirmButtonClass: 'btn btn-danger btn-fill',
                        buttonsStyling: false,
                        confirmButtonText: 'Dismiss'
                    });
                }
            }
          })
      },
      handleRestore (index, row) {
        row.isDeleted = null;
        row.isSuspended = null;
        row.save({ query: { $showSoftDeletes: true  }});
      },
      paginationTotal (value) {
        this.pagination.total = value
      },
      ...mapActions('users', { findUsers: 'find' })
    },
    watch: {
        'pagination.currentPage': function () {
            this.findMembers();
        },
        'pagination.perPage': function () {
            this.findMembers();
        }
    },
    async mounted () {
      this.groups = (await this.Group.find()).data;
      this.findMembers();
    }
  }
</script>
<style lang="scss">
    .deleted-row {
        td {
            background-color: #ffdada !important;
        }

        &.el-table__row--striped td {
            background-color: #f9a2a2 !important;
        }
    }
    .suspended-row {
        td {
            background-color: #fddbb1 !important;
        }

        &.el-table__row--striped td {
            background-color: #ffd197 !important;
        }
    }
</style>
