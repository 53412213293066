<template>
	<div class="row d-flex justify-content-center align-items-center w-100">
	  <div class="col-lg-4 col-md-6 col-sm-8">
		<card v-loading="saving">
			<div slot="header">
			  <h3 class="card-title text-center">Verifying your account</h3>
			</div>
			<p v-if="message" class="text-center" style="color: red; font-weight: 300;">{{ message }}</p>
			<p v-else class="text-center" style="color: #000; font-weight: 300;">Redirecting...</p>
		</card>
	  </div>
	</div>
</template>
<script>
	export default {
		computed: {
			key () {
				return this.$route.params.key;
			},
			VerifyAccount () {
				return this.$FeathersVuex.api.VerifyAccount;
			}
		},
		data() {
			return {
				message: false,
				saving: false
			};
		},
		async mounted () {
			const verify = new this.VerifyAccount({ key: this.key });
			try {
				await verify.create();
				this.$emit('done');
			} catch (e) {
				this.message = e.message;
			}
		}
	}
</script>
<style>
  .navbar-nav .nav-item p{
    line-height: inherit;
    margin-left: 5px;
  }
</style>
