<template>
  <div class="row">
    <div class="col-md-12">
      <h4 class="title">Manage Groups</h4>
    </div>

    <div class="col-12">
      <card>
        <div>
          <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
            <el-select
              class="select-default mb-3"
              style="width: 200px"
              v-model="pagination.perPage"
              placeholder="Per page">
              <el-option
                class="select-default"
                v-for="item in pagination.perPageOptions"
                v-loading="isFindGroupsPending"
                :key="item"
                :label="item"
                :value="item">
              </el-option>
            </el-select>
            <div>
            <el-input class="mb-3"
                      style="width: 200px"
                      placeholder="Search Groups"
                      v-model="searchQuery"
                      aria-controls="datatables"/>
            <l-button type="success" outline wide size="md" class="ml-3 mt-1" @click="dialogFormVisible = true">
                <span class="btn-label">
                    <i class="fa fa-plus"></i>
                </span>
                Add Group
              </l-button>
            </div>
          </div>
          <div class="col-sm-12">
            <el-table stripe
                      style="width: 100%;"
                      :data="groups.data"
                      border>
              <el-table-column min-width="100" prop="name" label="Group Name" />
              <el-table-column min-width="100" label="Owner">
                <template slot-scope="scope">
                    <router-link :to="'/members/' + scope.row.owner._id">{{ scope.row.owner.firstName }} {{ scope.row.owner.lastName }}</router-link>
                </template>
              </el-table-column>
              <el-table-column min-width="40" label="# of Members">
                <template slot-scope="scope">
                  {{ scope.row.members.length }}
                </template>
              </el-table-column>
              <el-table-column
                width="120"
                fixed="right"
                label="Actions">
                <template slot-scope="props">
                    <div class="btn-group">
                      <a v-tooltip.top-center="'Edit'" class="btn-info btn btn-sm"
                         @click="handleEdit(props.$index, props.row)"><i
                        class="fa fa-edit"></i></a>
                      <a v-tooltip.top-center="'Delete'" class="btn-danger btn btn-sm"
                         @click="handleDelete(props.$index, props.row)"><i class="fa fa-times"></i></a>
                    </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
          <div class="">
            <p class="card-category">Showing {{from + 1}} to {{to}} of {{total}} entries</p>
          </div>
          <l-pagination class="pagination-no-border"
                        v-model="pagination.currentPage"
                        :per-page="pagination.perPage"
                        :total="total">
          </l-pagination>
        </div>
        <el-dialog title="Add a Group" :visible.sync="dialogFormVisible">
          <form @submit.prevent="addGroup">
              <div>
                <fg-input label="Group Name"
                          type="text"
                          v-model="group.name"
                          placeholder="Enter group name (must be unique)">
                </fg-input>
                <l-alert class="text-left" :type="message.type" v-if="message">{{ message.text }}</l-alert>
                <div class="d-flex justify-content-between">
                    <button type="submit" class="btn btn-fill btn-success">Submit</button>
                    <span>
                      <label>Leaveable</label>
                      <l-switch v-model="group.leavable" class="ml-2">
                            <i class="fa fa-check" slot="on"></i>
                            <i class="fa fa-times" slot="off"></i>
                      </l-switch>
                      <label class="ml-2">Private</label>
                      <l-switch v-model="group.private" class="ml-2">
                            <i class="fa fa-check" slot="on"></i>
                            <i class="fa fa-times" slot="off"></i>
                      </l-switch>
                      <label class="ml-2">Secret</label>
                      <l-switch v-model="group.secret" class="ml-2">
                            <i class="fa fa-check" slot="on"></i>
                            <i class="fa fa-times" slot="off"></i>
                      </l-switch>
                    </span>
                </div>
              </div>
            </form>
        </el-dialog>
      </card>
    </div>
  </div>
</template>
<script>
  import { mapActions, mapGetters, mapState } from 'vuex';
  import LAlert from '@/components/Alert';
  import { Table, TableColumn, Select, Option, Dialog } from 'element-ui'
  import {Pagination as LPagination, Switch as LSwitch} from '@/components/index'
  import Fuse from 'fuse.js'

  export default {
    components: {
      LAlert,
      LSwitch,
      LPagination,
      [Select.name]: Select,
      [Option.name]: Option,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Dialog.name]: Dialog
    },
    data () {
      return {
        message: false,
        group: {},
        dialogFormVisible: false,
        options: [],
        pagination: {
          perPage: 5,
          currentPage: 1,
          perPageOptions: [5, 10, 25, 50]
        },
        searchQuery: ''
      }
    },
    computed: {
      ...mapState('groups', { isFindGroupsPending: 'isFindPending' }),
      ...mapGetters('groups', { findGroupsInStore: 'find' }),
      groupsParams () {
          return {
              query: {
                  $limit: this.pagination.perPage,
                  $skip: this.skip,
                  $sort: {
                     name: 1
                  },
                  name: {
                     $regex: this.searchQuery.split(' ').join('|'),
                     $options: 'i'
                  }
              }
          }
      },
      groups () {
          return this.findGroupsInStore(this.groupsParams);
      },
      Group () {
        return this.$FeathersVuex.api.Group;
      },
      skip () {
        return this.pagination.perPage * (this.pagination.currentPage - 1);
      },
      to () {
        let highBound = this.from + this.pagination.perPage
        if (this.total < highBound) {
          highBound = this.total
        }
        return highBound
      },
      from () {
        return this.pagination.perPage * (this.pagination.currentPage - 1)
      },
      total () {
        return this.groups.total;
      }
    },
    methods: {
       ...mapActions('groups', { findGroups: 'find' }),
       async addGroup () {
          this.message = false;
          try {
            await this.group.save();
            this.message = {
                type: 'success',
                text: 'Your group was added!'
            };
            this.group = new this.Group;
          } catch(e) {
            this.message = {
                type: 'danger',
                text: e.message
            }
          }
      },
      handleEdit (index, row) {
        this.$router.push('/groups/' + row._id);
      },
      async handleDelete (index, group) {
        try {
            await group.remove();
            this.$notify(
                {
                    title: 'Group deleted!',
                    icon: 'nc-icon nc-check-2',
                    horizontalAlign: 'right',
                verticalAlign: 'bottom',
                type: 'success'
            });
         } catch (e) {
            this.$notify(
            {
                title: e.message,
                icon: 'nc-icon nc-key-25',
                horizontalAlign: 'right',
                verticalAlign: 'bottom',
                type: 'danger'
            });
        }
      },
      paginationTotal (value) {
        this.pagination.total = value
      }
    },
    mounted () {
      this.group = new this.Group;
      this.findGroups(this.groupParams);
    }
  }
</script>
<style>
</style>
