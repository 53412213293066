var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"form"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.validate.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('ValidationProvider',{attrs:{"name":"dateTime","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"error":failed ? 'The Date/Time field is required': null,"hasSuccess":passed,"label":"Date/Time"}},[_c('el-date-picker',{attrs:{"type":"datetimerange","placeholder":"Datetime picker","format":"MMM dd, yyyy @ hh:mm a"},model:{value:(_vm.form.dateTimePicker),callback:function ($$v) {_vm.$set(_vm.form, "dateTimePicker", $$v)},expression:"form.dateTimePicker"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"name":"eventName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"error":failed ? 'The Event Name field is required': null,"hasSuccess":passed,"label":"Event Name","type":"text","placeholder":"Event Name"},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"eventDescription","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"error":failed ? 'The Event Description field is required': null,"hasSuccess":passed,"label":"Event Description"}},[_c('editor',{staticClass:"form-control",attrs:{"api-key":_vm.tinymceApiKey,"init":{menubar: false, branding: false},"rows":20,"type":"textarea"},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"name":"eventImage","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"error":failed ? 'The Event Image field is required': null,"hasSuccess":passed,"label":"Event Image","placeholder":"Event Image"}},[_c('el-upload',{attrs:{"drag":"","action":(_vm.apiURL + "/uploads"),"on-change":_vm.handleChange,"on-preview":_vm.handlePreview,"on-remove":_vm.handleRemove,"file-list":_vm.fileList,"headers":_vm.fileUploadHeaders,"list-type":"picture","multiple":""},model:{value:(_vm.form.image),callback:function ($$v) {_vm.$set(_vm.form, "image", $$v)},expression:"form.image"}},[_c('i',{staticClass:"el-icon-upload"}),_c('div',{staticClass:"el-upload__text"},[_vm._v("Drop file here or "),_c('em',[_vm._v("click to upload")])]),_c('div',{staticClass:"el-upload__tip",attrs:{"slot":"tip"},slot:"tip"},[_vm._v("jpg/png files with a size less than 500kb")])])],1)]}}])})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }