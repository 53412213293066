<template>
	<div class="card">
		<div class="container-fluid m-0 p-0">
			<div class="row m-0 p-0" style="height: calc(100vh - 150px) !important;">
				<div class="w-100 mobile-only d-none">
					<div class="d-flex justify-contents-between">
						<el-select
							:disabled="!messageGroups.length"
							v-model="activeMessageGroup"
							placeholder="Select a conversation.."
							class="select-default flex-fill"
							@change="(grp) => { this.activeMessageGroup = grp; }"
						>
							<el-option label="Select a conversation..." :value="false" disabled class="select-default" />
							<el-option
								class="select-default"
								v-for="messageGroup in messageGroups"
								:key="messageGroup._id"
								:label="messageGroup.label"
								:value="messageGroup">
							</el-option>
						</el-select>
						<l-button type="success" style="padding: 12px;" @click.prevent="startConversation = true;"><i class="fa fa-envelope" /></l-button>
					</div>
				</div>
				<div class="col-md-3 p-0 desktop-only" style="background: #eee; height: 100%; overflow: scroll;">
					<div class="container d-flex flex-column align-items-end h-100 p-0" v-loading="isFindMessageGroupsPending || hidingMessageGroup">
						<div class="row m-0 p-0 h-100 w-100 overflow-auto">
							<div class="col-12 m-0 p-0 w-100" v-if="messageGroups.length">
								<div
									v-for="messageGroup in messageGroups"
									:key="messageGroup._id"
									class="card m-2 p-2 message-group"
									:class="{ active: messageGroup._id == activeMessageGroup._id }"
									@click.prevent="activeMessageGroup = messageGroup;"
								>
									<b>{{messageGroup.label}}</b>
								</div>
							</div>
							<div class="col-12 m-0 p-0 w-100"  v-else>
								<div class="placeholder-message-group" v-for="i in 4" :key="i">
									<div class="text-line"></div>
								</div>
							</div>
						</div>
						<div class="row w-100 m-0 p-0">
							<div class="col-12">
								<l-button
									v-if="activeMessageGroup"
									type="success"
									wide
									size="md" class="w-100 mb-3"
									@click.prevent="startConversation = true;">
									<span class="btn-label">
										<i class="fa fa-plus"></i>
									</span>
									New Message
								</l-button>
							</div>
						</div>
					</div>
				</div>
				<div class="flex-fill h-100" v-loading="activeMessageGroup && isFindMessagesPending">
					<el-empty
						class="h-100"
						description="No Conversation Selected..." v-if="!activeMessageGroup">
						<l-button
							type="success"
							wide
							size="md" class="w-100 mb-3"
							@click.prevent="startConversation = true;">
							<span class="btn-label">
								<i class="fa fa-plus"></i>
							</span>
							New Message
						</l-button>
					</el-empty>
					<div class="container d-flex flex-column align-items-end w-100 h-100" v-else>
						<div
							class="row w-100 m-0 border-bottom desktop-only d-flex justify-content-between">
							<h3 class="text-center">{{ activeMessageGroup.label }}</h3>
							<div class="m-3">
								<l-button type="danger" @click.prevent="hideMessageGroup"><i class="fa fa-trash" :disabled="hidingMessageGroup" /></l-button>
							</div>
						</div>
						<div class="row h-100 w-100 m-0 p-3 overflow-auto messages">
							<!-- Chat bubbles -->
							<div class="container">
								<div
									v-for="message in rMessages"
									:key = "message._id"
									class="d-flex row"
									:class="{ 'justify-content-end': message.ownerId == user._id }"
								>
									<div
										class="message-bubble"
										:class="{ mine: message.ownerId == user._id }"
									>
										{{ message.text }}
									</div>
								</div>
							</div>
						</div>
						<div class="row w-100 m-0">
							<!-- Text input -->
							<form @submit.prevent="sendMessage" class="w-100" v-loading="sendingMessage">
								<fg-input v-model="messageText" class="w-100" placeholder="Enter a message..." />
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
		<el-dialog v-loading="creatingConversation" title="Start A Conversation" :visible.sync="startConversation" v-if="newMessageGroup">
			<div class="text-center">
				<el-select
					class="w-100 select-default"
					v-model="newMessageGroup.participants"
					multiple
					filterable
					remote
					reserve-keyword
					allow-create
					placeholder="Please enter a keyword"
					:remote-method="remoteMethod"
					:loading="loading">
					<el-option
						v-for="item in options"
						class="select-default"
						:key="item.value"
						:label="item.label"
						:value="item.value">
						<span style="float: left">{{ item.label }}</span>
						<span style="float: right; color: #8492a6; font-size: 13px; font-weight: bold;" class="mr-5">{{ item.type }}</span>
					</el-option>
				</el-select>
				<l-button type="success" wide class="mt-3" @click.prevent="createConversation">
					<i class="fa fa-envelope"></i>&nbsp; 
					Send A Message
				</l-button>
			</div>
		</el-dialog>
	</div>
</template>
<script>
	//import InfiniteLoading from 'vue-infinite-loading';
	import { Dialog, Empty, Select, Option } from 'element-ui';
	import { makeFindMixin } from 'feathers-vuex'
	export default {
		mixins: [
			makeFindMixin({
				service: 'message-groups',
				watch: 'startConversation'
			}),
			makeFindMixin({
				service: 'messages',
				queryWhen: 'activeMessageGroup',
				watch: 'activeMessageGroup'
			})
		],
		components: {
			[Dialog.name]: Dialog,
			[Empty.name]: Empty,
			[Select.name]: Select,
			[Option.name]: Option,
//			InfiniteLoading
		},
		data () {
			return {
				activeMessageGroup: false,
				messageText: null,
				sendingMessage: false,
				startConversation: false,
				userOptions: [],
				loading: false,
				newMessageGroup: false,
				hidingMessageGroup: false,
				creatingConversation: false
			}
		},
		computed: {
			type () {
				if (this.newMessageGroup.participants.length == 1) {
					return 0;
				}
				if (this.newMessageGroup.participants.length > 1) {
					return 1;
				}
				return false;
			},
			options () {
				return this.userOptions;
			},
			Group () {
				return this.$FeathersVuex.api.Group;
			},
			Message () {
				return this.$FeathersVuex.api.Message;
			},
			MessageGroup () {
				return this.$FeathersVuex.api.MessageGroup;
			},
			User () {
				return this.$FeathersVuex.api.User;
			},
			messageGroupsParams () {
				return {
					query: {
						hiddenBy: {
							$ne: this.user._id
						},
						$sort: {
							updatedAt: -1
						}
					}
				}
			},
			messageGroupsFetchParams () {
				return {
					query: {
						$sort: {
							updatedAt: -1
						}
					}
				}
			},
			messagesParams () {
				return {
					query: {
						groupId: this.activeMessageGroup._id,
						$sort: {
							createdAt: -1
						}
					}
				}
			},
			rMessages () {
				return JSON.parse(JSON.stringify(this.messages)).reverse();
			}
		},
		methods: {
			loadOlderMessages ($state) {
				console.log('load old');
				$state.complete();
			},
			loadNewerMessages ($state) {
				console.log('load new');
				$state.loaded();
			},
			async hideMessageGroup () {
				this.hidingMessageGroup = true;
				const groupId = this.activeMessageGroup._id.toString();
				this.activeMessageGroup = false;
				await this.$store.dispatch('message-groups/patch', [groupId, {
					hide: true
				}]);
				this.hidingMessageGroup = false;
			},
			async createConversation () {
				if(this.type === false) {
					alert('You need to add someone to talk to!');
					return;
				}
				this.creatingConversation = true;
				this.newMessageGroup.type = this.type;
				await this.newMessageGroup.save();
				this.newMessageGroup = new this.MessageGroup;
				this.startConversation = false;
				await this.findMessageGroups();
				this.activeMessageGroup = this.messageGroups[0];
				this.creatingConversation = false;
			},
			remoteMethod (query) {
				this.User.find({ query:
					{
						"$and": [
							{
								"_id": {
									$ne: this.user._id
								}
							},{
								"$or": [
									{
										email: {
											$regex: query.split(' ').join('|')
										}
									},
									{
										firstName: {
											$regex: query.split(' ').join('|')
										}
									},
									{
										lastName: {
											$regex: query.split(' ').join('|')
										}
									}
								]
							}
						]
					}
				}).then(res => {
					this.userOptions = [];
					for (let user of res.data) {
						const option = {
							type: 'user',
							label: user.firstName + ' ' + user.lastName,
							value: user._id
						};
						this.userOptions.push(option);
					}
				});
			},
			async sendMessage () {
				this.sendingMessage = true;
				const message = new this.Message;
				message.text = this.messageText;
				message.groupId = this.activeMessageGroup._id;
				await message.save();
				this.messageText = null;
				this.sendingMessage = false;
				let container = this.$el.querySelector(".messages");
				container.scrollTop = container.scrollHeight;
			}
		},
		mounted () {
			this.newMessageGroup = new this.MessageGroup;
		}
	}
</script>
<style lang="scss" scoped>
	.message-group {
		cursor: pointer;
		background: #eee;
		border-width: 0px;
		&:hover {
			background: #ddd;
		}
		&.active {
			background: #fff;
			padding-left: 20px !important;
			border-width: 1px 0px 1px 1px !important;
			border-radius: 30px 0px 0px 30px !important;
			margin-right: 0px !important;
		}
	}

	.message-bubble {
		margin: 5px;
		max-width: 65%;
		padding: 15px;
		border-radius: 30px;
		background: #666;
		color: #fff;
		border-top-left-radius: 0px;
		&.mine {
			background: #63d8f1;
			color: #fff;
			border-bottom-right-radius: 0px;
			border-top-left-radius: 30px;
		}
	}

	@media only screen and (max-width: 1026px) {
		.desktop-only {
			display: none;
		}
		.mobile-only {
			display: block !important;
		}
	}

	.placeholder-message-group {
		animation-name: stretch;
		animation-duration: 2.0s;
		animation-timing-function: ease-out;
		animation-direction: alternate;
		animation-iteration-count: infinite;
		animation-play-state: running;
		padding: 15px;
		margin: 20px;
		border-radius: 5px;
		background: #ddd;
		&:first-child {
			border-radius: 30px 0px 0px 30px;
			margin-right: 0px;
		}
		.text-line {
			border-radius: 6px;
			padding: 6px;
			background: linear-gradient(90deg, rgba(180,180,180,.25) 0%, rgba(86,86,98,.15) 35%, rgba(171,171,171,.05) 100%);
		}
	}

	@keyframes stretch {
		0% {
			transform: scale(1);
			opacity: 0.5
		}

		50% {
		}

		100% {
			transform: scale(1.01);
			opacity: 1
		}
	}
</style>
