<template>
    <div class="row d-flex justify-content-center">
      <div class="col-md-8">
        <div class="header-text">
          <h2>Admin Registration</h2>
          <h4>Fill out the form below to register for an admin account</h4>
          <hr>
        </div>
      </div>
      <div class="col-md-5 ml-auto">
        <fade-render-transition :duration="200">
          <div class="media">
            <div class="media-left">
              <div class="icon">
                <i class="nc-icon nc-circle-09"></i>
              </div>
            </div>
            <div class="media-body">
              <h4>Secure Access</h4>
              <p>Support the club securely by registering via our secure portal. New accounts pending review by Super Admin</p>
            </div>
          </div>
        </fade-render-transition>
        <fade-render-transition :delay="200" :duration="200">
          <div class="media">
            <div class="media-left">
              <div class="icon">
                <i class="nc-icon nc-preferences-circle-rotate"></i>
              </div>
            </div>
            <div class="media-body">
              <h4>Full App Management</h4>
              <p>Moderate users, groups, events and more with your admin account</p>
            </div>
          </div>
        </fade-render-transition>
        <fade-render-transition :delay="400" :duration="200">
          <div class="media">
            <div class="media-left">
              <div class="icon">
                <i class="nc-icon nc-planet"></i>
              </div>
            </div>
            <div class="media-body">
              <h4>Available Anywhere</h4>
              <p>Access your account anywhere you have internet in order to easily make changes even when you're on the go.</p>
            </div>
          </div>
        </fade-render-transition>
      </div>
      <div class="col-md-4 mr-auto">
        <RegisterForm />
      </div>
    </div>
</template>
<script>
	import RegisterForm from '@/components/Forms/RegisterForm';
	import { FadeRenderTransition } from 'src/components/index'

	export default {
		data () {
			return {
				error: false,
				user: false,
				confirmPassword: null
			}
		},
		components: {
			FadeRenderTransition,
			RegisterForm
		},
		computed: {
			User () {
				return this.$FeathersVuex.api.User;
			}
		},
		methods: {
			async createUser () {
				try {
					await this.user.save();
				} catch (e) {
					this.error = e.message;
				}
			},
			toggleNavbar () {
				document.body.classList.toggle('nav-open')
			},
			closeMenu () {
				document.body.classList.remove('nav-open')
				document.body.classList.remove('off-canvas-sidebar')
			}
		},
		mounted () {
			this.user = new this.User;
		},
		beforeDestroy () {
			this.closeMenu()
		}
	}
</script>
