import feathersClient, { makeServicePlugin, BaseModel } from '../../feathers-client'

class Comment extends BaseModel {
  constructor(data, options) {
    super(data, options)
  }
  // Define default properties here
  static instanceDefaults() {
    return {
      text: null,
      postId: null,
      ownerId: null,
      createdAt: null,
      updatedAt: null
    }
  }
}
Comment.modelName = 'Comment';
const servicePath = 'comments'
const servicePlugin = makeServicePlugin({
  Model: Comment,
  service: feathersClient.service(servicePath),
  servicePath
})

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
})

export default servicePlugin
