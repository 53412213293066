<template>
	<div class="row d-flex justify-content-center align-items-center w-100">
	  <div class="col-lg-4 col-md-6 col-sm-8">
		<card>
			<div slot="header">
			  <h3 class="card-title text-center">Action Completed</h3>
			</div>
			<p class="text-center" style="color: #000; font-weight: 300;">You can close this window and return to the app!</p>
		</card>
	  </div>
	</div>
</template>
<script>
	export default {}
</script>
<style>
  .navbar-nav .nav-item p{
    line-height: inherit;
    margin-left: 5px;
  }
</style>
