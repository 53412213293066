<template>
  <footer class="footer">
    <div class="container-fluid d-flex flex-wrap justify-content-between">
      <nav>
        <ul class="footer-menu">
          <li>
          </li>
        </ul>
      </nav>
      <div class="copyright">
        Lincoln Club of Orange County &copy; {{ (new Date).getFullYear() }}
      </div>
    </div>
  </footer>
</template>
<script>
  export default {}

</script>
<style>

</style>
