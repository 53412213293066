<template>
	<form @submit.prevent="validate" ref="form">
		<div class="row">
			<div class="col-md-12">
				<fg-input label="Access Type">
					<div style="clear: both;"></div>
					<radio class="mr-3" style="float: left;" label="0" v-model="inviteType">Public</radio>
					<radio class="mr-3" style="float: left;" label="1" v-model="inviteType">Private</radio>
					<radio class="mr-3" style="float: left;" label="2" v-model="inviteType">Secret</radio>
					<div style="clear: both;"></div>
				</fg-input>
				<fg-input label="Select Invitees"
						v-if="inviteType > 0">
					<el-select
						class="w-100 select-default"
						v-model="invitees"
						multiple
						filterable
						remote
						reserve-keyword
						allow-create
						placeholder="Please enter a keyword"
						:remote-method="remoteMethod"
						:loading="loading">
						<el-option
							v-for="item in options"
							class="select-default"
							:key="item.value"
							:label="item.label"
							:value="item.value">
							<span style="float: left">{{ item.label }}</span>
      						<span style="float: right; color: #8492a6; font-size: 13px; font-weight: bold;" class="mr-5">{{ item.type }}</span>
						</el-option>
					</el-select>
				</fg-input>
			</div>
		</div>
	</form>
</template>
<script>
import { DatePicker, TimeSelect, Slider, Tag, Input, Button, Select, Option, Upload } from 'element-ui'
import {
	Progress as LProgress,
	Switch as LSwitch,
	FormGroupInput as FgInput,
	Radio
} from '@/components/index'
import { extend } from "vee-validate";
import { required, email } from "vee-validate/dist/rules";

extend("required", required);
extend("email", email);

export default {
	components: {
		FgInput,
		[DatePicker.name]: DatePicker,
		[TimeSelect.name]: TimeSelect,
		[Slider.name]: Slider,
		[Tag.name]: Tag,
		[Input.name]: Input,
		[Button.name]: Button,
		[Option.name]: Option,
		[Select.name]: Select,
		[Upload.name]: Upload,
		Radio
	},
	data() {
		return {
			inviteType: '0',
			invitees: [],
			fileList: [],
			userOptions: [],
			groupOptions: [],
			loading: false
		};
	},
	computed: {
		options () {
			return this.groupOptions.concat(this.userOptions);
		},
		Group () {
			return this.$FeathersVuex.api.Group;
		},
		User () {
			return this.$FeathersVuex.api.User;
		}
	},
	methods: {
		reset () {
			this.$refs.form.reset();
		},
		remoteMethod (query) {
			this.User.find({ query:
				{
					"$or": [
						{
							email: {
								$regex: query.split(' ').join('|')
							}
						},
						{
							firstName: {
								$regex: query.split(' ').join('|')
							}
						},
						{
							lastName: {
								$regex: query.split(' ').join('|')
							}
						}
					]
				}
			}).then(res => {
				this.userOptions = [];
				for (let user of res.data) {
					const option = {
						type: 'user',
						label: user.firstName + ' ' + user.lastName + ' <' + user.email + '>',
						value: 'user:' + user._id
					};
					this.userOptions.push(option);
				}
			});
			this.Group.find({ query:
				{
					"$or": [
						{
							name: {
								$regex: query.split(' ').join('|')
							}
						}
					]
				}
			}).then(res => {
				this.groupOptions = [];
				for (let group of res.data) {
					const option = {
						type: 'group',
						label: group.name,
						value: 'group:' + group._id
					};
					this.groupOptions.push(option);
				}
			});
		}
	}
}
</script>
<style>
.el-upload-dragger, .el-upload {
	width: 100%;
}
</style>
