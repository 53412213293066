<template>
<div v-loading="!group" style="min-height: 100vh;">
  <card v-if="group" stickyHeader>
    <div slot="header">
      <div class="alert alert-info d-flex justify-content-center justify-content-sm-between" v-if="(group.isDeleted || group.isSuspended) && moderator._id">
          <div>
            {{ group.name }}
          </div>
          <div style="font-weight: bold;">{{ moderationDate | moment('calendar') }}</div>
      </div>
      <div class="d-flex justify-content-between">
        <div class="w-50">
            <h4 class="title mt-0 mb-1">{{ group.name }} {{ group.lastName }}</h4>
            <h6 class="text-small mb-3">Created {{ group.createdAt | moment("from") }}</h6>
        </div>
        <div class="w-50 text-right p-2">
            <el-button
                :loading="formLoading"
                :disabled="!isDirty"
                type="success"
                @click.prevent="updateGroup">
                <i class="fa fa-save"></i> Update Event
            </el-button>
            <p v-if="isDirty" class="text-muted" style="font-size: 10px;">Something changed... Don't forget to save!</p>
        </div>
      </div>
    </div>
    <div>
      <form>
        <div class="row">
          <div class="col-md-12">
            <fg-input type="text"
                      label="Name"
                      placeholder="Name"
                      v-model="form.name">
            </fg-input>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <label>Members</label>
            <el-table stripe
                      style="width: 100%;"
                      :data="group.membersList"
                      border>
                <el-table-column min-width="100" label="First Name" prop="firstName" />
                <el-table-column min-width="100" label="Last Name" prop="lastName" />
            </el-table>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <label>Leavable</label>
            <l-switch v-model="form.leavable" class="ml-2" color="green" @change="updateGroup">
              <i class="fa fa-check" slot="on"></i>
              <i class="fa fa-times" slot="off"></i>
            </l-switch>
            <label class="ml-2">Private</label>
            <l-switch v-model="form.private" class="ml-2" @change="updateGroup">
              <i class="fa fa-check" slot="on"></i>
              <i class="fa fa-times" slot="off"></i>
            </l-switch>
            <label class="ml-2">Secret</label>
            <l-switch v-model="form.secret" class="ml-2" @change="updateGroup">
              <i class="fa fa-check" slot="on"></i>
              <i class="fa fa-times" slot="off"></i>
            </l-switch>
          </div>
        </div>
        
        <DangerZone class="text-center">
            <button
                class="btn btn-danger btn-outline btn-wd"
                @click.prevent="remove">
                <i class="fa fa-warning"></i> Delete Group
            </button>
            <button
                class="btn btn-success btn-fill btn-wd"
                @click.prevent="restore"
                v-if="group.isDeleted || group.isSuspended">
                <i class="fa fa-play"></i> Restore Group
            </button>
        </DangerZone>
        <div class="clearfix"></div>
      </form>
    </div>
    <el-dialog title="Link HubSpot ID" :visible.sync="dialogFormVisible">
      <form @submit.prevent="updateGroup(); dialogFormVisible=false;">
          <div>
            <fg-input label="HubSpot ID"
                      type="text"
                      v-model="group.hubspotId"
                      placeholder="Enter users HubSpot ID">
            </fg-input>
            <l-alert class="text-left" :type="message.type" v-if="message">{{ message.text }}</l-alert>
            <button type="submit" class="btn btn-fill btn-success">Submit</button>
          </div>
        </form>
    </el-dialog>
  </card>
</div>
</template>
<script>
	import moment from 'moment';
	import Swal from 'sweetalert2';
	import DangerZone from '@/components/DangerZone';
	import { Switch as LSwitch } from '@/components/index';
	import { Button, Dialog, Select, Option, Table, TableColumn } from 'element-ui';
	export default {
		components: {
			DangerZone,
			LSwitch,
			[Button.name]: Button,
			[Dialog.name]: Dialog,
			[Select.name]: Select,
			[Option.name]: Option,
			[Table.name]: Table,
			[TableColumn.name]: TableColumn
		},
		data () {
			return {
				dangerZone: false,
				dialogFormVisible: false,
				group: false,
				form: false,
				message: false,
				moderator: {},
				moderationDate: new Date,
				formLoading: false
			}
		},
		computed: {
			groupId () {
				return this.$route.params.groupId;
			},
			Group () {
				return this.$FeathersVuex.api.Group;
			},
			isDirty () {
				return JSON.stringify(this.group) !== JSON.stringify(this.form);
			}
		},
		methods: {
			remove () {
				Swal.fire({
					title: 'Are you sure?',
					text: `${this.group.name} members group privileges will be revoked. Are you sure you want to delete this group?`,
					type: 'warning',
					showCancelButton: true,
					confirmButtonClass: 'btn btn-danger btn-fill',
					cancelButtonClass: 'btn btn-default btn-simple',
					confirmButtonText: 'Delete ' + this.group.name + ' ' + this.group.lastName,
					buttonsStyling: false
				}).then(async result => {
					if (result.isConfirmed) {
						try {
							await this.group.remove();
							this.$router.push('/groups');
							Swal.fire({
								title: 'Deleted!',
								text: `${this.group.name} was deleted`,
								type: 'success',
								confirmButtonClass: 'btn btn-success btn-fill',
								buttonsStyling: false
							})
						} catch(e) {
							Swal.fire({
								title: 'Error!',
								text: `Sorry we couldn't delete ${this.group.name} right now. If the problem continues please contact an administrator.`,
								type: 'error',
								confirmButtonClass: 'btn btn-danger btn-fill',
								buttonsStyling: false,
								confirmButtonText: 'Dismiss'
							});
						}
					}
				})
			},
			async updateGroup () {
				this.formLoading = true;
				try {
					await this.form.save();
					this.form.commit();
					this.$notify({
						title: 'Group updated!',
						icon: 'nc-icon nc-check-2',
						horizontalAlign: 'right',
						verticalAlign: 'bottom',
						type: 'success'
					});
				} catch (e) {
					this.$notify({
						title: e.message,
						icon: 'nc-icon nc-key-25',
						horizontalAlign: 'right',
						verticalAlign: 'bottom',
						type: 'danger'
					});
				}
				this.formLoading = false;
			},
			beforeUnload (e) {
				if (this.isDirty) {
					e.preventDefault();
					e.returnValue = '';
				}
			},
			async protectUnsaved () {
				if (this.isDirty) {
					const result = await Swal.fire({
						title: 'Something Changed...',
						text: `You are about to lose unsaved changes. Do you still want to leave this page?`,
						type: 'warning',
						showCancelButton: true,
						cancelButtonClass: 'btn btn-default btn-simple',
						confirmButtonText: 'Leave Now',
						confirmButtonClass: 'btn btn-warning btn-simple',
						buttonsStyling: false
					}).then(async result => {
						if (result.value) {
							return true;
						} else {
							return false;;
						}
					});
					return result;
				}
				return true;
			}
		},
		async beforeRouteLeave (to, from, next) {
			const allow = await this.protectUnsaved();
			if (allow) {
				next();
			} else {
				next(false);
			}
		},
		async mounted () {
			this.group = await this.Group.get(this.groupId);
			this.form = this.group.clone();
			window.addEventListener('beforeunload', this.beforeUnload);
		},
		beforeDestroy () {
			window.removeEventListener('beforeunload', this.beforeUnload);
		}
	}

</script>
<style scoped>
/deep/ .card-header {
    box-shadow: 0px 1px 5px rgba(0,0,0,.05);
}

/deep/ .el-tabs__header {
    position: sticky;
    top: 78px;
    background: #fff;
    z-index: 20;
}
</style>
