<template>
<div>
	<div class="wrapper wrapper-full-page">
		<nav class="navbar navbar-expand-lg navbar-transparent navbar-absolute">
			<div class="container">
				<div class="navbar-wrapper">
					<a class="navbar-brand" href="#" @click.prevent>Lincoln Club App</a>
				</div>
			</div>
		</nav>
		<div class="full-page login-page" data-color="black">
			<!--   you can change the color of the filter page using: data-color="blue | azure | green | orange | red | purple | black" -->
			<div class="content">
				<div class="container">
					<div class="action-page">
						<div class="centered">
							<forgot-password v-if="action == 'forgot-password'" @done="handleRedirect" />
							<change-email v-if="action == 'approve-email'" @done="handleRedirect" />
							<verify-account v-if="action == 'verify-account'" @done="handleRedirect" />
							<fallback v-if="action == 'fallback'" />
						</div>
					</div>
				</div>
			</div>

			<footer class="footer footer-transparent">
				<div
					class="container d-flex flex-lg-row flex-column justify-content-lg-between justify-content-center align-items-center">
					<nav>
						<ul>
						</ul>
					</nav>
					<div class="copyright">
						Lincoln Club of Orange County &copy; {{ (new Date).getFullYear() }}
					</div>
				</div>
			</footer>

			<div class="full-page-background"
			 style="background-image: url(/static/img/lincoln-fullscreen.webp) ">
			</div>
		</div>
	</div>
</div>
</template>

<script>
	import Fallback from '@/actions/Fallback';
	import ChangeEmail from '@/actions/ChangeEmail';
	import ForgotPassword from '@/actions/ForgotPassword';
	import VerifyAccount from '@/actions/VerifyAccount';
	export default {
		data () {
			return {}
		},
		components: {
			Fallback,
			ChangeEmail,
			ForgotPassword,
			VerifyAccount
		},
		computed: {
			action () {
				return this.$route.params.action;
			},
			isIphone () {
				return navigator.userAgent.toLowerCase().indexOf("iphone") > -1;
			},
			isAndroid () {
				return navigator.userAgent.toLowerCase().indexOf("android") > -1;
			}
		},
		methods: {
			handleRedirect () {
				const { deepLink, iphoneFallback, androidFallback, fallback } = this.$route.query;
				setTimeout(() => {
					if (iphoneFallback && this.isIphone) {
						window.location = iphoneFallback;
					} else if (androidFallback && this.isAndroid) {
						window.location = androidFallback;
					} else if (fallback) {
						window.location = fallback;
					} else {
						this.$router.push('/run/fallback');
					}
				}, 50);
				if (deepLink) {
					try {
						window.location = deepLink;
					} catch (e) { }
				} 
			}
		}
	}
</script>
<style lang="scss">
  .action-page {
    .full-page > .content,
    .centered {
      min-height: calc(100vh - 160px);
    }
    .not-found-icon {
      margin-right: 10px;
    }
    .centered {
      h1, i {
        font-size: 50px;
      }
      p {
        font-size: 20px;
      }
      display: flex;
      padding-bottom: 150px;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: white;
    }
  }
  .navbar-nav {
    min-width: 300px;
  }
  .navbar-nav .nav-item p {
    line-height: inherit;
    margin-left: 5px;
  }
  .navbar-wrapper{
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
  }
  .wrapper-full-page .navbar-toggler,
  .wrapper-full-page .navbar-collapse .navbar-nav{
    margin-right: 30px;
  }
  .navbar-collapse .navbar-nav .nav-link {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .full-page-background {
    background-size: cover;
    filter: blur(5px);
    background-attachment: fixed;
  }
</style>
