<template>
  <ul class="nav nav-mobile-menu">
    <drop-down>
      <template slot="title">
        <i class="nc-icon nc-bullet-list-67"></i>
      </template>
      <b class="dropdown-item" style="font-weight: bold;">
        <i class="nc-icon nc-circle-09"></i> {{ user.firstName }} {{ user.lastName }}
      </b>
      <router-link class="dropdown-item" to="/support">
        <i class="nc-icon nc-umbrella-13"></i> Support
      </router-link><router-link class="dropdown-item" to="/messenger">
      <i class="nc-icon nc-send"></i> Messenger
    </router-link>
      <div class="divider"></div>
      <a href="#" @click.prevent="logout" class="dropdown-item text-danger">
        <i class="nc-icon nc-button-power"></i> Log out
      </a>
    </drop-down>
  </ul>
</template>
<script>
  import DropDown from '@/components/Dropdown.vue'

  export default {
    components: {
      DropDown
    }
  }
</script>
<style>
</style>
