<template>
	<ValidationObserver ref="form">
		<form @submit.prevent="validate">
			<div class="row">
				<div class="col-md-12">
					<ValidationProvider
					name="dateTime"
					rules="required"
					v-slot="{ passed, failed }"
					>
						<fg-input :error="failed ? 'The Date/Time field is required': null"
							:hasSuccess="passed" label="Date/Time">
							<el-date-picker
								v-model="form.dateTimePicker"
								type="datetimerange"
								placeholder="Datetime picker"
								format="MMM dd, yyyy @ hh:mm a">
							</el-date-picker>
						</fg-input>
					</ValidationProvider>
					<ValidationProvider
					name="eventName"
					rules="required"
					v-slot="{ passed, failed }"
					>
						<fg-input :error="failed ? 'The Event Name field is required': null"
							:hasSuccess="passed" label="Event Name"
								v-model="form.title"
								type="text"
								placeholder="Event Name">
						</fg-input>
					</ValidationProvider>
					<ValidationProvider
					name="eventDescription"
					rules="required"
					v-slot="{ passed, failed }"
					>
						<fg-input :error="failed ? 'The Event Description field is required': null"
							:hasSuccess="passed" label="Event Description">
								<editor v-model="form.description"
								:api-key="tinymceApiKey"
								:init="{menubar: false, branding: false}"
								:rows="20"
								type="textarea"
								class="form-control" />
						</fg-input>
					</ValidationProvider>
					<ValidationProvider
					name="eventImage"
					rules="required"
					v-slot="{ passed, failed }"
					>
						<fg-input :error="failed ? 'The Event Image field is required': null"
							:hasSuccess="passed" label="Event Image"
								placeholder="Event Image">
							<el-upload
								drag
								v-model="form.image"
								:action="`${apiURL}/uploads`"
								:on-change="handleChange"
								:on-preview="handlePreview"
								:on-remove="handleRemove"
								:file-list="fileList"
								:headers="fileUploadHeaders"
								list-type="picture"
							multiple>
							  <i class="el-icon-upload"></i>
							  <div class="el-upload__text">Drop file here or <em>click to upload</em></div>
							  <div class="el-upload__tip" slot="tip">jpg/png files with a size less than 500kb</div>
							</el-upload>
						</fg-input>
					</ValidationProvider>
				</div>
			</div>
		</form>
	</ValidationObserver>
</template>
<script>
import Editor from '@tinymce/tinymce-vue';
import { DatePicker, TimeSelect, Slider, Tag, Input, Button, Select, Option, Upload } from 'element-ui'
import {
	Progress as LProgress,
	Switch as LSwitch,
	FormGroupInput as FgInput
} from '@/components/index'
import { extend } from "vee-validate";
import { required, email } from "vee-validate/dist/rules";

extend("required", required);
extend("email", email);

export default {
	components: {
		FgInput,
		[DatePicker.name]: DatePicker,
		[TimeSelect.name]: TimeSelect,
		[Slider.name]: Slider,
		[Tag.name]: Tag,
		[Input.name]: Input,
		[Button.name]: Button,
		[Option.name]: Option,
		[Select.name]: Select,
		[Upload.name]: Upload,
		Editor
	},
	props: ['initDate'],
	data() {
		return {
			form: {
				dateTimePicker: [],
				name: null,
				description: null,
				image: null
			},
			fileList: []
		};
	},
	computed: {
		apiURL () {
			return process.env.VUE_APP_FEATHERS_API_URL;
		},
		fileUploadHeaders () {
			return {
				'Authorization': `Bearer ${this.accessToken}`
			}
		}
	},
	methods: {
		reset() {
			this.$refs.form.reset();
		},
		validate() {
			return this.$refs.form.validate().then(res => {
				this.$emit('on-validated', res)
				return res
			})
		},
		handlePreview (file, fileList) {
			console.log(file, fileList);
		},
		handleRemove(file) {
			console.log(file);
		},
		handleChange (file, fileList) {
			this.form.image = file;
		}
	},
	mounted () {
		this.form.dateTimePicker = [this.initDate.start, this.initDate.end];
		
	},
	watch: {
		initDate (val) {
			this.form.dateTimePicker = [val.start, val.end];
		}
	}
}
</script>
<style>
.el-upload-dragger, .el-upload {
	width: 100%;
}
</style>
