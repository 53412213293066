import { sprintf } from 'sprintf-js';

import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const ActionRunner = () => import(/* webpackChunkName: "group-action" */ '@/actions/Runner');

const AuthLayout = () => import(/* webpackChunkName: "group-auth" */ '@/layouts/AuthLayout');
const SignIn = () => import(/* webpackChunkName: "group-auth" */ '@/views/auth/SignIn');
//const SignOut = () => import(/* webpackChunkName: "group-auth" */ '@/views/auth/SignOut');
const CreateAccount = () => import(/* webpackChunkName: "group-auth" */ '@/views/auth/CreateAccount');
const NotFound = () => import(/* webpackChunkName: "group-auth" */ '@/views/auth/NotFound');
const NotAuthorized = () => import(/* webpackChunkName: "group-auth" */ '@/views/auth/NotAuthorized');

const DashboardLayout = () => import(/* webpackChunkName: "group-dashboard" */ '@/layouts/DashboardLayout');
const Messenger = () => import(/* webpackChunkName: "group-dashboard" */ '@/views/Messenger');
const Dashboard = () => import(/* webpackChunkName: "group-dashboard" */ '@/views/Dashboard');
const Members = () => import(/* webpackChunkName: "group-dashboard" */ '@/views/Members');
const MembersProfile = () => import(/* webpackChunkName: "group-dashboard" */ '@/views/Members/MembersProfile');
const Groups = () => import(/* webpackChunkName: "group-dashboard" */ '@/views/Groups');
const GroupsEdit = () => import(/* webpackChunkName: "group-dashboard" */ '@/views/Groups/GroupsEdit');
const Events = () => import(/* webpackChunkName: "group-dashboard" */ '@/views/Events');
const EventsEdit = () => import(/* webpackChunkName: "group-dashboard" */ '@/views/Events/EventsEdit');

const requiresAuth = true;
const requiresAdmin = true;
const hasSearch = true;
const goToSignIn = true;
const loggedOutOnly = true;
const ignoreRoute = true;

const metaDescription = 'Admin area for the Lincoln Club App';

const adminRoutes = [
	{
		path: '/',
		redirect: '/dashboard'
	},
	{
		path: '',
		component: DashboardLayout,
		children: [
			{
				path: '/dashboard',
				component: Dashboard,
				name: 'dashboard',
				meta: {
					requiresAuth,
					requiresAdmin,
					goToSignIn,
					seo: {
						title: 'Dashboard'
					}
				}
			},
			{
				path: '/messenger',
				component: Messenger,
				name: 'messenger',
				meta: {
					requiresAuth,
					requiresAdmin,
					goToSignIn,
					seo: {
						title: 'Messenger'
					}
				}
			},
			{
				path: '/members',
				component: Members,
				name: 'members',
				meta: {
					requiresAuth,
					requiresAdmin,
					goToSignIn,
					seo: {
						title: 'Members'
					}
				}
			},
			{
				path: '/members/:memberId',
				component: MembersProfile,
				name: 'members-profile',
				meta: {
					requiresAuth,
					requiresAdmin,
					goToSignIn,
					seo: {
						title: 'Members Profile'
					}
				}
			},
			{
				path: '/groups',
				component: Groups,
				name: 'groups',
				meta: {
					requiresAuth,
					requiresAdmin,
					goToSignIn,
					seo: {
						title: 'Groups'
					}
				}
			},
			{
				path: '/groups/:groupId',
				component: GroupsEdit,
				name: 'groups-edit',
				meta: {
					requiresAuth,
					requiresAdmin,
					goToSignIn,
					seo: {
						title: 'Group Detail'
					}
				}
			},
			{
				path: '/events',
				component: Events,
				name: 'events',
				meta: {
					requiresAuth,
					requiresAdmin,
					goToSignIn,
					seo: {
						title: 'Events'
					}
				}
			},
			{
				path: '/events/:eventId/:activeTab?',
				component: EventsEdit,
				name: 'events-edit',
				meta: {
					requiresAuth,
					requiresAdmin,
					goToSignIn,
					seo: {
						title: 'Event Detail'
					}
				}
			}
		]
	},
	{
		path: '',
		component: AuthLayout,
		children: [
			{
				name: 'sign-in',
				path: '/sign-in/:flag?',
				component: SignIn,
				meta: {
					loggedOutOnly,
					sitemap: {
						loc: '/sign-in'
					},
					seo: {
						title: 'Sign In',
						metaTags: [
							{
								name: 'description',
								content: metaDescription
							}
						],
					},
					pageClass: "login-page"
				}
			},
			/*{
				path: '/sign-out',
				component: SignOut,
				meta: {
					sitemap: {
						ignoreRoute
					}
				}
			},*/
			{
				name: 'create-account',
				path: '/create-account',
				component: CreateAccount,
				meta: {
					loggedOutOnly,
					seo: {
						title: 'Create Account',
						metaTags: [
							{
								name: 'description',
								content: metaDescription
							}
						],
					},
					pageClass: "register-page"
				}
			},
			{
				name: 'not-found',
				path: '/not-found/:path?',
				component: NotFound,
				meta: {
					seo: {
						title: 'Not Found',
						metaTags: [
							{
								name: 'description',
								content: metaDescription
							}
						],
					},
					pageClass: "login-page"
				}
			},
			{
				name: 'not-authorized',
				path: '/not-authorized',
				component: NotAuthorized,
				meta: {
					seo: {
						title: 'Not Authorized',
						metaTags: [
							{
								name: 'description',
								content: metaDescription
							}
						],
					},
					pageClass: "login-page"
				}
			},
			/*{
				path: '/verify-account/:key',
				component: VerifyAccount,
				meta: {
					sitemap: {
						ignoreRoute
					}
				}
			},
			{
				path: '/forgot-password/:key?',
				component: ForgotPassword,
				meta: {
					loggedOutOnly,
					sitemap: {
						ignoreRoute
					},
					seo: {
						title: 'Forgot Password',
						metaTags: [
							{
								name: 'description',
								content: metaDescription
							}
						],
					}
				}
			}*/
		]
	},
/*
	{
		path: '/admin',
		component: Admin,
		meta: {
			requiresAuth,
			requiresAdmin,
			goToSignIn,
			sitemap: {
				ignoreRoute
			}
		},
		children: [
			{
				path: '',
				redirect: '/admin/dashboard',
				component: AdminDashboard
			},
			{
				path: '/admin/dashboard',
				component: AdminDashboard
			},
			{
				path: '/admin/users',
				component: AdminUsers
			},
			{
				path: '/admin/notifications',
				component: AdminNotifications
			},
			{
				path: '/admin/offers',
				component: AdminOffers
			}
		]
	},
	{
		path: '/not-found',
		component: NotFound,
		meta: {
			sitemap: {
				ignoreRoute
			}
		}
	},
	*/
	{ path: '*', name: 'catch-all' }
];

const actionRoutes = [
	{
		path: '/run/:action/:key?',
		component: ActionRunner,
		meta: {
			sitemap: {
				ignoreRoute
			}
		}
	}
];

let routes;
switch (location.hostname)
{
	case 'localhost': routes = actionRoutes.concat(adminRoutes);
	break;
	case 'stage.admin.lincolnclub.app':
	case 'admin.lincolnclub.app': routes = adminRoutes;
	break;
	case 'stage.actions.lincolnclub.app':
	case 'actions.lincolnclub.app': routes = actionRoutes;
	break;
	default: routes = actionRoutes;
}

const router = new VueRouter({
	mode: 'history',
	scrollBehavior (to, from, savedPosition) {
		if (to.hash) {
			return { selector: to.hash };
		} else if (savedPosition) {
			return savedPosition;
		} else {
			return { x: 0, y: 0 };
		}
	},
	routes
});

const guards =  {
	init (store, maintenance) {
		router.beforeEach((to, from, next) => {
			/* HANDLE ADMIN GUARDS */
			if (maintenance) {
				if (to.query.lemmein == 'true') {
					window.localStorage.setItem('lemmein', true);
					router.push('/');
				} else if (!window.localStorage.getItem('lemmein') && to.name != 'coming-soon') {
					router.push('/coming-soon');
					return;
				}
			} else if (to.name == 'coming-soon') {
				router.push('/');
				return;
			}
			const user = store.getters["auth/user"];
			const isAuthenticated = store.getters["auth/isAuthenticated"];
			const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
			const requiresAdmin = to.matched.some(record => record.meta.requiresAdmin);
			const goToSignIn = to.matched.some(record => record.meta.goToSignIn);
			const loggedOutOnly = to.matched.some(record => record.meta.loggedOutOnly);

			const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.seo && r.meta.seo.title);
			const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.seo && r.meta.seo.metaTags);
			if( nearestWithTitle ) { document.title = sprintf('%s - Lincoln Club Admin', nearestWithTitle.meta.seo.title); }
			else { document.title = 'Lincoln Club Admin'; }

			Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));
			if ( nearestWithMeta ) {
				nearestWithMeta.meta.seo.metaTags.map(tagDef => {
				const tag = document.createElement('meta');
				Object.keys(tagDef).forEach(key => {
					tag.setAttribute(key, tagDef[key]);
				});
				tag.setAttribute('data-vue-router-controlled', '');
				return tag;
				}).forEach(tag => document.head.appendChild(tag));
			}
			if (requiresAuth && !isAuthenticated) {
				if (['home'].includes(to.name)) {
					next();
				} else if (goToSignIn) {
					router.push('/sign-in?redirect=' + encodeURIComponent(to.fullPath.substring(1)));
				} else {
					router.push('/');
				}
			} else if (loggedOutOnly && isAuthenticated) {
				router.push({
					path: '/dashboard',
					query: to.query
				});
			} else if (
				requiresAdmin
				&& ["admin", "moderator"].every(val => !user.roles.includes(val))
			) {
				router.push('/not-authorized');
			} else if (to.name == 'catch-all') {
				router.push({
					name: 'not-found',
					params: {
						path: to.path
					}
				});
			} else {
				next();
			}
		});
	}
};

export { routes, router, guards }
