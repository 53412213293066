<template>
	<div>
		<ValidationObserver ref="form">
			<form @submit.prevent="validate">
				<ValidationProvider
					name="location"
					rules="required"
					v-slot="{ passed, failed }"
				>
				<fg-input
					id="pac-input"
					type="text"
					label="Location"
					placeholder="Search your event location here..."
					:error="failed ? 'The Location field is required': null"
					:hasSuccess="passed"
					v-model="form.location" />
				</ValidationProvider>
			</form>
		</ValidationObserver>
		<label>Map Preview</label>
		<div id="regularMap" class="mb-5"></div>
	</div>
</template>
<script>
import { extend } from "vee-validate";
import { required, email } from "vee-validate/dist/rules";

extend("required", required);
import { Loader, LoaderOptions } from "google-maps";
const loader = new Loader(process.env.VUE_APP_GOOGLE_API_KEY, {
	libraries: ['places']
});
export default {
	data() {
		return {
			map: null,
			form: {
				location: null
			}
		};
	},
	methods: {
		reset () {
			this.$refs.form.reset();
		},
		validate() {
			return this.$refs.form.validate().then(res => {
				this.$emit('on-validated', res)
				return res
			})
		}
	},
	mounted () {
		loader.load().then(google => {	
			let latLng = new window.google.maps.LatLng(33.699740, -117.846400); // default to LC Office
			this.map = new window.google.maps.Map(document.getElementById('regularMap'), {
				zoom: 8,
				center: latLng,
				scrollwheel: false,
				mapTypeControl: false
			})
			if('geolocation' in navigator) {
				navigator.geolocation.getCurrentPosition(position => {
					latLng = new window.google.maps.LatLng(position.coords.latitude, position.coords.longitude);
					this.map.panTo(latLng);
				}, () => {
					console.log('Could not geolocate user!');
				});
			}
			const input = document.getElementById("pac-input");
			const searchBox = new window.google.maps.places.SearchBox(input);
			let markers = [];
			searchBox.addListener("places_changed", () => {
				const places = searchBox.getPlaces();

				if (places.length == 0) {
					return;
				}

				// Clear out the old markers.
				markers.forEach((marker) => {
					marker.setMap(null);
				});
				markers = [];

				// For each place, get the icon, name and location.
				const bounds = new window.google.maps.LatLngBounds();

				places.forEach((place) => {
					this.form.location = place.formatted_address;
					if (!place.geometry || !place.geometry.location) {
						console.log("Returned place contains no geometry");
						return;
					}

					const icon = {
						url: place.icon,
						size: new window.google.maps.Size(71, 71),
						origin: new window.google.maps.Point(0, 0),
						anchor: new window.google.maps.Point(17, 34),
						scaledSize: new window.google.maps.Size(25, 25),
					};
					const marker = new window.google.maps.Marker({
						map: this.map,
						icon,
						title: place.name,
						position: place.geometry.location,
						formatted_address: place.formatted_address
					});
					marker.addListener("click", () => {
						this.form.location = marker.formatted_address;
					});

					markers.push(
						marker
					);
					
					if (place.geometry.viewport) {
						// Only geocodes have viewport.
						bounds.union(place.geometry.viewport);
					} else {
						bounds.extend(place.geometry.location);
					}
				});
				this.map.fitBounds(bounds);
			});
		});
	},
	beforeUnmount () {
		this.map = null;
	}
}
</script>
<style>
	#regularMap {
		height: 300px;
		border-radius: 10px;
		box-shadow: 3px 3px 20px #ccc;
	}
</style>
