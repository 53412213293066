<template>
	<div class="row">
		<div class="col-12">
			<card>
				<div>
					<div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
						<el-select
							class="select-default mb-3"
							style="width: 200px"
							v-model="pagination.perPage"
							placeholder="Per page">
							<el-option
								class="select-default"
								v-for="item in pagination.perPageOptions"
								:key="item"
								:label="item"
								:value="item">
							</el-option>
						</el-select>
						<el-input 
							class="mb-3"
							style="width: 200px"
							placeholder="Filter Events"
							v-model="searchQuery"
							@change="$emit('change', searchQuery)"
							:clearable="true"
							:debounce="500"
							aria-controls="datatables"/>
					</div>
					<div class="col-sm-12">
						<el-table stripe
								v-loading="areEventsLoading"
								style="width: 100%;"
								:data="events.data"
								:lazy="true"
								:row-class-name="tableRowClassName"
								:default-sort="{prop: 'name', order: 'ascending'}"
								@sort-change="sortResults"
								border>
							<el-table-column min-width="100" prop="title" label="Title" sortable />
							<el-table-column min-width="100" label="Start">
								<template slot-scope="props">
									<i v-tooltip.top-center="momentFrom(props.row.start)" style="font-size: 12px;">{{ props.row.start | moment('calendar') }}</i>
								</template>
							</el-table-column>
							<el-table-column
							width="150"
							fixed="right">
								<template slot-scope="props">
									<div class="btn-group">
										<a v-tooltip.top-center="'Edit'" class="btn-info btn-sm btn"
										@click="handleEdit(props.$index, props.row)"><i
										class="fa fa-edit"></i></a>
										<a v-tooltip.top-center="'Preview'" class="btn-success btn-sm btn"
										@click="handlePreview(props.$index, props.row)"><i
										class="fa fa-play"></i></a>
										<a v-tooltip.top-center="'Delete'" class="btn-danger btn-sm btn"
										@click="handleDelete(props.$index, props.row)"><i class="fa fa-times"></i></a>
									</div>
								</template>
							</el-table-column>
						</el-table>
					</div>
				</div>
				<div slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
				  <div class="">
					<p class="card-category">Showing {{from + 1}} to {{to}} of {{total}} entries</p>
				  </div>
				  <l-pagination class="pagination-no-border"
								v-model="pagination.currentPage"
								:per-page="pagination.perPage"
								:total="events.total">
				  </l-pagination>
				</div>
			</card>
		</div>
	</div>
</template>
<script>
	import moment from 'moment';
	import Swal from 'sweetalert2';
	import { mapState, mapGetters, mapActions } from 'vuex'
	import { Table, TableColumn, Select, Option } from 'element-ui'
	import {Pagination as LPagination} from '@/components/index'
	import Fuse from 'fuse.js'

	export default {
		props: ['start', 'end', 'events'],
		components: {
			LPagination,
			[Select.name]: Select,
			[Option.name]: Option,
			[Table.name]: Table,
			[TableColumn.name]: TableColumn
		},
		data () {
			return {
				pagination: {
					perPage: 5,
					currentPage: 1,
					perPageOptions: [5, 10, 25, 50],
					total: 0
				},
				searchQuery: ''
			}
		},
		computed: {
			...mapState('events', { areEventsLoading: 'isFindPending' }),
			...mapGetters('events', { findEventsInStore: 'find' } ),
			eventsGet () {
				return this.findEventsInStore({
					query: {
						$limit: this.pagination.perPage,
					}
				});
			},
			to () {
				let highBound = this.from + this.pagination.perPage
				if (this.total < highBound) {
					highBound = this.total
				}
				return highBound
			},
			from () {
				return this.pagination.perPage * (this.pagination.currentPage - 1)
			},
			total () {
				return this.events.total
			}
		},
		methods: {
			momentFrom(date) {
				return moment(date).from(new Date);
			},
			sortResults (sort) {
				const newSort = {}
				newSort[sort.prop] = sort.order == 'ascending' ? 1 : -1;
				this.sort = newSort;
				this.findEvents();
			},
			tableRowClassName ({row, rowIndex}) {
				if (row.isDeleted) {
					return 'deleted-row';
				}
				if (row.isSuspended) {
					return 'suspended-row';
				}
			},
			handleEdit (index, row) {
				this.$router.push('/events/' + row._id);
			},
			handlePreview (index, row) {
				this.$emit('preview', row);
			},
			handleDelete (index, row) {
				Swal.fire({
					title: 'Are you sure?',
					text: `${row.title} will not be recoverable. All RSVP's will be deleted as well.`,
					type: 'warning',
					showCancelButton: true,
					confirmButtonClass: 'btn btn-danger btn-fill',
					cancelButtonClass: 'btn btn-default btn-simple',
					confirmButtonText: 'Delete ' + row.title,
					buttonsStyling: false
				}).then(async result => {
					if (result.isConfirmed) {
						try {
							await row.remove();
							Swal.fire({
								title: 'Deleted!',
								text: `${row.title} was deleted`,
								type: 'success',
								confirmButtonClass: 'btn btn-success btn-fill',
								buttonsStyling: false
							})
						} catch(e) {
							Swal.fire({
								title: 'Error!',
								text: `Sorry we couldn't delete ${row.firstName} right now. If the problem continues please contact an administrator.`,
								type: 'error',
								confirmButtonClass: 'btn btn-danger btn-fill',
								buttonsStyling: false,
								confirmButtonText: 'Dismiss'
							});
						}
					}
				})
			}
		}
	  }
</script>
<style lang="scss">
    .deleted-row {
        td {
            background-color: #ffdada !important;
        }

        &.el-table__row--striped td {
            background-color: #f9a2a2 !important;
        }
    }
    .suspended-row {
        td {
            background-color: #fddbb1 !important;
        }

        &.el-table__row--striped td {
            background-color: #ffd197 !important;
        }
    }
    .swal2-container {
        z-index: 99999999 !important;
    }
</style>
