import feathersClient, { makeServicePlugin, BaseModel } from '../../feathers-client'

class Event extends BaseModel {
  constructor(data, options) {
    super(data, options)
  }
  // Define default properties here
  static instanceDefaults() {
    return {
      title: null,
      description: null,
      location: null,
      start: new Date,
      end: new Date,
      uploadId: null,
      invitees: []
    }
  }
}
Event.modelName = 'Event';
const servicePath = 'events';
const servicePlugin = makeServicePlugin({
  Model: Event,
  service: feathersClient.service(servicePath),
  servicePath
})

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
})

export default servicePlugin
