<template>
  <router-view></router-view>
</template>

<script>
	export default {
		methods: {
			checkUserStatus (user) {
				if (user._id == this.user._id && (user.isSuspended || user.isDeleted)) {
					this.logout();
				}
			}
		},
		computed: {
			User () {
				return this.$FeathersVuex.api.User;
			}
		},
		mounted () {
			this.User.on('patched', this.checkUserStatus);
		},
		beforeUnmount () {
			this.User.off('patched', this.checkUserStatus);
		}
	}
</script>
