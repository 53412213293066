<template>
	<div>
		<div class="text-center alert alert-danger mt-4 mb-0" style="z-index: 1">
			<div class="row">
				<h4 class="text-left mt-0 mb-0 col-md-6">Danger Zone</h4>
				<div class="col-md-6 text-right">
					<l-switch v-model="show" class="mt-1" color="red">
						<i class="fa fa-unlock" slot="on"></i>
						<i class="fa fa-lock text-danger" slot="off"></i>
					</l-switch>
				</div>
			</div>
		</div>
		<transition name="slide">
		<div v-if="show" style="border: solid 1px red; z-index: 0; top: -3px; position: relative;" class="mt-0 p-3">
			<slot />
		</div>
		</transition>
	</div>
</template>
<script>
	import { Switch as LSwitch } from '@/components/index';
	export default {
		components: {
			LSwitch
		},
		data () {
			return {
				show: false
			}
		}
	}
</script>
<style scoped>
.slide-enter-active {
  animation: slide-in 0.5s;
}
.slide-leave-active {
  animation: slide-in 0.5s reverse;
}
@keyframes slide-in {
  0% {
    transform-origin: 0% 0%;
    transform: scaleY(0);
  }
  100% {
    transform-origin: 0% 0%;
    transform: scale(1);
  }
}
</style>
