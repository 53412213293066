var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row d-flex justify-content-center align-items-center w-100"},[_c('div',{staticClass:"col-lg-4 col-md-6 col-sm-8"},[(_vm.form && _vm.key)?_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.saving),expression:"saving"}],on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('fade-render-transition',[_c('card',[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('h3',{staticClass:"card-title text-center"},[_vm._v("Reset Password")])]),_c('div',[_c('ValidationProvider',{attrs:{"name":"password","rules":"required|min:4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"type":"password","vid":"password","error":failed ? 'The Password field is required': null,"hasSuccess":passed,"label":"Password","name":"password"},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"confirm-password","rules":"required|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"type":"password","error":failed ? 'Your password doesn\'t match': null,"hasSuccess":passed,"name":"confirm-password","label":"Confirm Password"},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}})]}}],null,true)})],1),_c('div',{staticClass:"text-center mt-5"},[(_vm.message)?_c('l-alert',{staticClass:"text-left",attrs:{"type":_vm.message.type}},[_vm._v(_vm._s(_vm.message.text))]):_vm._e(),_c('button',{staticClass:"btn btn-fill btn-success btn-round btn-wd ",attrs:{"type":"submit"}},[_vm._v("Update Password")])],1)])],1)],1)]}}],null,false,177906434)}):_c('card',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.saving),expression:"saving"}]},[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('h3',{staticClass:"card-title text-center"},[_vm._v("Reset Password")])]),_c('l-alert',{attrs:{"type":"danger"}},[_c('b',[_vm._v("Invalid Key")]),_c('br'),_vm._v(" We couldn't find the password reset record you're looking for. You can start the process again by entering your email below...")]),_c('fg-input',{attrs:{"type":"email","label":"Email Address","placeholder":"Email Address"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('div',{staticClass:"text-center mt-5"},[(_vm.message)?_c('l-alert',{staticClass:"text-left",attrs:{"type":_vm.message.type}},[_vm._v(_vm._s(_vm.message.text))]):_vm._e(),_c('button',{staticClass:"btn btn-fill btn-info btn-round btn-wd",attrs:{"type":"submit","disabled":!_vm.email},on:{"click":function($event){$event.preventDefault();return _vm.resend.apply(null, arguments)}}},[_vm._v("Reset Password")])],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }