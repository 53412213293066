import feathersClient, { makeServicePlugin, BaseModel } from '../../feathers-client'

class Broadcast extends BaseModel {
  constructor(data, options) {
    super(data, options)
  }
  // Define default properties here
  static instanceDefaults() {
    return {
      title: null,
      body: null,
      ownerId: null,
      createdAt: null
    }
  }
}
Broadcast.modelName = 'Broadcast';
const servicePath = 'broadcasts'
const servicePlugin = makeServicePlugin({
  Model: Broadcast,
  service: feathersClient.service(servicePath),
  servicePath
})

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
})

export default servicePlugin
