import Vue from 'vue'

import CompositionApi from '@vue/composition-api';
Vue.use(CompositionApi);

Vue.use(require('vue-moment'));

import LightBootstrap from './light-bootstrap-main'
// Plugins
const App = () => import('@/App')

// router setup
import { router, guards } from '@/modules/router';
import store from '@/modules/store';
import '@/modules/mixins';
Vue.use(LightBootstrap)

guards.init(store, (process.env.VUE_APP_MAINTENANCE == 'true' ? true : false));

/* eslint-disable no-new */
store.dispatch('auth/authenticate')
.catch(() => {}).then(() => {
	new Vue({
		el: '#app',
		render: h => h(App),
		router,
		store
	})
});
