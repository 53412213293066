<template>
	<div class="row d-flex justify-content-center align-items-center w-100">
	  <div class="col-lg-4 col-md-6 col-sm-8">
		<card v-loading="saving">
			<div slot="header">
			  <h3 class="card-title text-center">Change Email</h3>
			</div>
			<l-alert :type="message.type" v-if="message">{{ message.text }}</l-alert>
		</card>
	  </div>
	</div>
</template>
<script>
	import LAlert from '@/components/Alert';
	import { extend } from "vee-validate";
	import { required, email, min, confirmed } from "vee-validate/dist/rules";

	extend("email", email);
	extend("required", required);
	extend("confirmed", confirmed);
	extend("min", min);

	export default {
		components: {
			LAlert
		},
		computed: {
			ChangeEmail () {
				return this.$FeathersVuex.api.ChangeEmail;
			},
			key () {
				return this.$route.params.key;
			}
		},
		data() {
			return {
				message: false,
				saving: false
			};
		},
		async mounted () {
			this.saving = true;
			if (this.key) {
				try {
					await this.ChangeEmail.find({ query: { key: this.key } }).then(async res => {
						if (res.total === 1) {
							await this.$store.dispatch('change-email/update', [res.data[0]._id, {  key: this.key }]);
						} else {
							throw 'No email change record!';
						}
					});
					this.message = {
						type: 'success',
						text: 'Your email address has been updated. Make sure to use your new email address the next time you log into the app!'
					}
					this.$emit('done');
				} catch (e) {
					console.log(e);
					this.message = {
						type: 'danger',
						text: 'There was an error approving your email change request. If the error persists please contact a club administrator.'
					}
				}
			} else {
				this.message = {
					type: 'danger',
					text: 'Invalid or no key provided!'
				}
			}
			this.saving = false;
		}
	}
</script>
<style>
  .navbar-nav .nav-item p{
    line-height: inherit;
    margin-left: 5px;
  }
</style>
