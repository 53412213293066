<template>
  <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
    <form autocomplete="false" @submit.prevent="handleSubmit(submit)" v-if="user">
      <card>
        <div>
          <ValidationProvider
            rules="required|email"
            v-slot="{ passed, failed }"
          >
            <fg-input  type="email"
                       label="Email Address"
                       :error="failed ? 'The Email field is required': null"
                       :hasSuccess="passed"
                       v-model="user.email">
            </fg-input>
          </ValidationProvider>

          <ValidationProvider
            vid="confirmation"
            rules="required"
            v-slot="{ passed, failed }"
          >
            <fg-input  type="password"
                       label="Password"
                       :error="failed ? 'The Password field is required': null"
                       :hasSuccess="passed"
                       v-model="user.password">
            </fg-input>
          </ValidationProvider>

          <ValidationProvider
            rules="required|confirmed:confirmation"
            v-slot="{ passed, failed }"
          >
            <fg-input  type="password"
                       label="Confirm Password"
                       :error="failed ? 'Your password doesn\'t match': null"
                       :hasSuccess="passed"
                       v-model="confirmPassword">
            </fg-input>
          </ValidationProvider>
          
          <ValidationProvider
            rules="required"
            v-slot="{ passed, failed }"
          >
            <fg-input  type="text"
                       label="First Name"
                       :error="failed ? 'This field is required': null"
                       :hasSuccess="passed"
                       v-model="user.firstName">
            </fg-input>
          </ValidationProvider>
          
          <ValidationProvider
            rules="required"
            v-slot="{ passed, failed }"
          >
            <fg-input  type="text"
                       label="Last Name"
                       :error="failed ? 'This field is required': null"
                       :hasSuccess="passed"
                       v-model="user.lastName">
            </fg-input>
          </ValidationProvider>
        </div>
        <div class="card-footer text-right">
          <l-alert class="text-left" :type="message.type" dismissible v-if="message">{{ message.text }}</l-alert>
          <button type="submit" class="btn btn-fill btn-info btn-wd" :disabled="saving">Register</button>
        </div>

      </card>
    </form>
  </ValidationObserver>
</template>
<script>
	import LAlert from '@/components/Alert';
	import { extend } from "vee-validate";
	import { required, email, confirmed } from "vee-validate/dist/rules";

	extend("email", email);
	extend("required", required);
	extend("confirmed", confirmed);

	export default {
		components: {
			LAlert
		},
		data() {
			return {
				saving: false,
				message: false,
				user: false,
				confirmPassword: null
			};
		},
		computed: {
			User () {
				return this.$FeathersVuex.api.User;
			}
		},
		methods: {
			async submit() {
				this.saving = true;
				try {
					await this.user.save();
					this.message = {
						type: 'success',
						text: "Thanks for signing up! You'll be able to login once your account is approved. In the meantime please check your inbox for an email verification link..."
					};
					this.user = new this.User;
					this.confirmPassword = null;
					this.$refs.observer.reset();
				} catch (e) {
					this.message = {
						type: 'danger',
						text: e.message
					};
				}
				this.saving = false;
			}
		},
		mounted () {
			this.user = new this.User;
		}
	}
</script>
<style>
</style>
