<template>
	<div class="container-fluid">
		<event-preview :previewEvent="previewEvent" :key="previewEvent._id" v-if="previewEvent" @close="previewEvent = false" />
		<div class="row">
			<div class="col-md-12">
				<l-alert class="text-left" :type="message.type" v-if="message">
					{{ message.text }}
				</l-alert>
				<Transition name="bounce">
					<CreateEvent :date="selectedDate" v-if="createEvent" @saved="hideEventForm" />
				</Transition>
			</div>
		</div>
		<div class="row">
			<div class="col-md-6">
				<events-calendar @change="loadEvents" @date-selected="dateSelected" :events="events" />
			</div>
			<div class="col-md-6">
				<events-list @change="searchEvents" @preview="renderEventPreview" :start="start" :end="end" :events="events" />
			</div>
		</div>
	</div>
</template>
<script>
	import EventPreview from '@/views/Events/Preview';
	import { mapActions } from 'vuex';
	import LAlert from '@/components/Alert';
	import CreateEvent from '@/components/Wizards/CreateEvent';
	import {Table, TableColumn} from 'element-ui';
	import EventsCalendar from '@/views/Events/Calendar';
	import EventsList from '@/views/Events/List';
	export default {
		components: {
			LAlert,
			CreateEvent,
			EventsCalendar,
			EventsList,
			EventPreview
		},
		data() {
			return {
				message: false,
				createEvent: false,
				selectedDate: false,
				start: new Date,
				end: new Date,
				events: false,
				search: false,
				previewEvent: false
			};
		},
		computed: {
			Event () {
				return this.$FeathersVuex.api.Event;
			}
		},
		methods: {
			...mapActions('events', { findEvents: 'find' }),
			renderEventPreview(event) {
				this.previewEvent = event;
			},
			dateSelected (date) {
				this.selectedDate = date;
				this.createEvent = true;
				window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                });
			},
			hideEventForm () {
				this.createEvent = false;
				this.message = {
					type: 'success',
					text: 'Your event was created and should reflect in the calendar shortly...'
				};
				setTimeout(() => { this.message = false; }, 5000);
			},
			searchEvents (search) {
				this.search = search;
				this.loadEvents({ start: this.start, end: this.end });
			},
			async loadEvents ({ start, end }) {
				this.start = start;
				this.end = end;
				const query = {
					$and: [{
						$or: [{
							start: {
								"$gte": start,
								"$lte": end
							}
						}, {
							end: {
								"$gte": start,
								"$lte": end
							}
						}]
					}],
					$sort: {
						start: 1
					}
				};
				if (this.search.length) {
					query.$and.push({
						title: {
							$regex: this.search.split(' ').join('|'),
							$options: 'i'
						}
					});
				}
				this.events = await this.findEvents({ query });
			},
			async reloadEvents () {
				return this.loadEvents({ start: this.start, end: this.end });
			}
		},
		mounted () {
			this.Event.on('created', this.reloadEvents);
			this.Event.on('patched', this.reloadEvents);
			this.Event.on('removed', this.reloadEvents);
		},
		beforeUnmount () {
			this.Event.off('created', this.reloadEvents);
			this.Event.off('patched', this.reloadEvents);
			this.Event.off('removed', this.reloadEvents);
		}
	}
</script>
<style>
.el-loading-spinner .path {
  stroke: #66615b !important;
}
.bounce-enter-active {
  animation: bounce-in 1s ease;
}
.bounce-leave-active {
  animation: bounce-in 1s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
</style>
