<template>
	<div class="row">
		<div class="col-lg-12 col-md-12">
			<edit-profile-form :member-id="$route.params.memberId" :key="$route.params.memberId" />
		</div>
	</div>
</template>
<script>
import EditProfileForm from './UserProfile/EditProfileForm.vue'

export default {
	components: {
		EditProfileForm,
	},
	data () {
		return {
			member: false
		}
	},
	computed: {
		User () {
			return this.$FeathersVuex.api.User;
		}
	}
}
</script>
<style>

</style>
