import Vue from 'vue';
Vue.mixin({
	data () {
		return {
			tinymceApiKey: process.env.VUE_APP_TINYMCE_API_KEY
		}
	},
	computed: {
		redirect () {
			return this.$route.query.redirect || false;
		},
		user () {
			return this.$store.getters["auth/user"] || {};
		},
		accessToken () {
			return this.$store.state.auth.accessToken;
		},
		isAuthenticated () {
			return this.$store.getters["auth/isAuthenticated"];
		},
		isVerified () {
			return this.user.isVerified || this.user.oauthVerified || false;
		}
	},
	methods: {
		scrollTop () {
			window.scroll({
				top: 0,
				behavior: 'smooth'
			});
		},
		async authImage (id) {
			return await fetch(
				`${process.env.VUE_APP_FEATHERS_API_URL}/uploads/render/${id}`,
				{
					headers: {
						Authorization: `Bearer ${this.accessToken}`
					}
				})
				.then(r => r.blob())
				.then(b => {
					return new Promise(resolve => {
						let reader = new window.FileReader();
						reader.onload = function () {
							resolve(this.result);
						}
						reader.readAsDataURL(b)
					});
				});
		},
		async logout () {
			try {
				await this.$store.dispatch('auth/logout');
			} catch (e) {
				console.log('LOGOUT ISSUE:', e);
			}
			this.$router.push('/sign-in');
		},
		classList (element, method, ...args) {
			const root = document.getElementsByTagName(element)[0];
			root.classList[method](...args);
		},
		getUrlParts(url) {
			const el = document.createElement('a');
			el.href = url;
			return el;
		},
		includeScript ( URL, callback, async ) {
			async = typeof async === "undefined" ? false : true;
			const scripts = document.getElementsByTagName('script');
			for (let i = scripts.length; i--;) {
				if (scripts[i].src == URL) {
					return callback();
				}
			}
			let documentTag = document, tag = 'script',
				object = documentTag.createElement(tag),
				scriptTag = documentTag.getElementsByTagName(tag)[0];
			object.src = URL;
			object.defer = true;
			object.async = async;
			if (callback) { object.addEventListener('load', function (e) { callback(null, e); }, false); }
			scriptTag.parentNode.insertBefore(object, scriptTag);
		},
		cleanQuery (...args) {
			const keys = Array.from(args);
			let query = Object.assign({}, this.$route.query);
			for (let key of keys) {
				delete query[key];
			}
			this.$router.replace({ query });
		}
	}
});
