<template>
	<div>
		<div class="row">
			<div class="col-xl-3 col-md-6">
				<stats-card :title="stats.members.toString()" subTitle="Members">
					<div slot="header" class="icon-user">
						<i class="nc-icon nc-single-02 text-success"></i>
					</div>
				</stats-card>
			</div>

			<div class="col-xl-3 col-md-6">
				<stats-card :title="stats.events.toString()" subTitle="Events">
					<div slot="header" class="icon-success">
						<i class="nc-icon nc-grid-45 text-success"></i>
					</div>
				</stats-card>
			</div>

			<div class="col-xl-3 col-md-6">
				<stats-card :title="stats.posts.toString()" subTitle="Posts">
					<div slot="header" class="icon-danger">
						<i class="nc-icon nc-align-left-2 text-danger"></i>
					</div>
				</stats-card>
			</div>

			<div class="col-xl-3 col-md-6">
				<stats-card :title="stats.comments.toString()" subTitle="Comments">
					<div slot="header" class="icon-info">
						<i class="nc-icon nc-chat-round text-primary"></i>
					</div>
				</stats-card>
			</div>
		</div>
		<h2 class="title mt-0">Activity</h2>
		<card class="p-3">
			<form class="filters mb-4">
				<div class="row">
					<div class="form-group col-md-4">
						<label>Methods</label>
						<el-select multiple class="select-default mr-3"
							size="large"
							v-model="filters.activity.methods"
							placeholder="Filter Methods"
						>
							<el-option v-for="option in ['create', 'update', 'remove']"
								class="select-default"
								:value="option"
								:label="option"
								:key="option">
							</el-option>
						</el-select>
					</div>
					<div class="form-group col-md-4">
						<label>Services</label>
						<el-select multiple class="select-default mr-3"
							size="large"
							v-model="filters.activity.services"
							placeholder="Filter Services"
						>
							<el-option v-for="option in ['users', 'posts', 'events', 'comments'].sort()"
								class="select-default"
								:value="option"
								:label="option"
								:key="option">
							</el-option>
						</el-select>
					</div>
					<div class="form-group col-md-4">
						<label>Limit</label>
						<el-select class="select-default"
							size="large"
							v-model="filters.activity.limit"
							placeholder="Limit"
							@change="loadActivity"
						>
							<el-option v-for="option in [5, 10, 25, 50]"
								class="select-default"
								:value="option"
								:label="option"
								:key="option">
							</el-option>
						</el-select>
					</div>
				</div>
			</form>
			<el-timeline class="ml-0 pl-0">
				<template v-for="(activities, group) in activityFeed">
					<el-timeline-item :timestamp="group" placement="top" :key="group">
						<transition-group name="bounce">
							<l-alert
								v-for="activity in activities"
								:type="getActivityType(activity)"
								class="col-12 d-flex justify-content-between"
								:key="activity._id"
							>
								<div>
									<router-link v-if="activity.path == 'users'" :to="'/members/' + activity.result._id" class="text-white font-weight-bold">{{ activity.result.firstName }} {{ activity.result.lastName }}</router-link> 
									<template v-else>
										A<template v-if="anServices.includes(activity.path)">n</template> <router-link :to="'/' + activity.path + '/' + activity.result._id" class="text-white font-weight-bold">{{ activity.path.replace(/s$/, '') }}</router-link>
									</template>
									{{ displayActivityMethodText(activity) }}
									<template v-if="activity.user">
										by <router-link v-if="(activity.path == 'users' && activity.user._id != activity.result._id) || activity.path != 'users'" :to="'/members/' + activity.user._id" class="text-white font-weight-bold">{{ activity.user.firstName }} {{ activity.user.lastName }}</router-link>
										   <span v-else>themself</span>
									</template>
								</div>
								<div>
									<em>{{ activity.createdAt | moment('calendar') }}</em>
								</div>
							</l-alert>
						</transition-group>
					</el-timeline-item>
				</template>
			</el-timeline>
		</card>
	</div>
</template>
<script>
	import moment from 'moment';
	import { mapState, mapGetters, mapActions } from 'vuex'
	import { StatsCard, Card } from '@/components/index';
	import { Option, Select, Timeline, TimelineItem } from 'element-ui';
	import LAlert from 'src/components/Alert';
	export default {
		components: {
			Card,
			StatsCard,
			[Timeline.name]: Timeline,
			[TimelineItem.name]: TimelineItem,
			[Option.name]: Option,
			[Select.name]: Select,
			LAlert
		},
		data () {
			return {
				anServices: ['events'],
				stats: {
					members: 0,
					events: 0,
					posts: 0,
					comments: 0
				},
				filters: {
					activity: {
						methods: [],
						services: [],
						limit: 25
					}
				},
				intervals: {
					activity: null
				}
			}
		},
		computed: {
			...mapGetters('activity', { findActivitiesInStore: 'find' } ),
			activityFeed () {
				const activities = this.findActivitiesInStore({
					query: { $sort: { createdAt: -1 }, $limit: this.filters.activity.limit }
				}).data;
				const timelineGroups = {};
				activities.forEach(activity => {
					const groupName = moment(activity.createdAt).from(new Date);
					if (typeof timelineGroups[groupName] === "undefined") {
						timelineGroups[groupName] = [];
					}
					timelineGroups[groupName].push(activity);
				});
				return timelineGroups;
			}
		},
		methods: {
			displayActivityMethodText (activity) {
				let text = 'was';
				if (activity.path == 'users' && activity.method != 'patch') {
					if (activity.method == 'create') {
						text = 'signed up';
					}
					if (activity.method == 'remove') {
						text += ' deleted';
					}

					return text;
				}
				if (activity.method == 'patch') {
					let updateText = ' updated';
					if (activity.diff) {
						activity.diff.forEach(diff => {
							if (diff.path[0] == 'isSuspended') {
								if (diff.lhs == null) {
									updateText = ' suspended';
								} else {
									updateText = ' unsuspended';
								}
							}
						});
					}

					text += updateText;
				} else {
					text += ' ' + activity.method.replace(/e$/, '') + 'ed';
				}
				return text;
			},
			getTimelineTimestamp (date) {
				return moment(date).from(new Date);
			},
			getActivityType(activity) {
				if (['patch', 'update'].includes(activity.method)) {
					if (activity.diff) {
						for (let diff of activity.diff) {
							if (diff.path[0] == 'isSuspended') {
								return 'warning';
							}
						}
					}
					return 'info';
				}
				if (activity.method == 'remove') {
					return 'danger';
				}
				if (activity.method == 'create') {
					return 'success';
				}
			},
			loadActivity () {
				this.findActivity({
					query: { "diff.path": { $ne: "isDeleted" }, $sort: { createdAt: -1 }, $limit: this.filters.activity.limit } 
				});
			},
			...mapActions('activity', { findActivity: 'find' }),
		},
		mounted () {
			this.$store.dispatch('users/count', { query: {} }).then( count => {
				this.stats.members = count;
			});
			this.$store.dispatch('events/count', { query: {} }).then( count => {
				this.stats.events = count;
			});
			this.$store.dispatch('posts/count', { query: {} }).then( count => {
				this.stats.posts = count;
			});
			this.$store.dispatch('comments/count', { query: {} }).then( count => {
				this.stats.comments = count;
			});
			this.loadActivity();
			this.intervals.activity = setInterval(this.loadActivity, 60 * 1000);
		},
		beforeUnmount () {
			clearInterval(this.intervals.activity);
		}
	}
</script>
<style>
.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}
</style>
