<template>
	<div class="row">
		<div class="col-12">
			<MessengerUI />
		</div>
	</div>
</template>
<script>
import MessengerUI from '@/components/MessengerUI';
export default {
	components: {
		MessengerUI    
	}
}
</script>
<style>
</style>
