<template>
<div v-loading="!event" style="min-height: 100vh;">
  <card v-if="event" stickyHeader>
    <div slot="header">
      <div class="d-flex justify-content-between">
        <div class="w-50">
            <h4 class="title mt-0 mb-1">{{ event.title }}</h4	>
            <h6 class="text-small mb-3">{{ event.start | moment("calendar") }}</h6>
        </div>
        <div class="w-50 text-right">
            <el-button
                :loading="formLoading"
                :disabled="!isDirty"
                type="success"
                @click.prevent="save">
                <i class="fa fa-save"></i> Update Event
            </el-button>
            <p v-if="isDirty" class="text-muted" style="font-size: 10px;">Something changed... Don't forget to save!</p>
        </div>
      </div>
    </div>
    <el-tabs v-model="activeTab" @tab-click="updateTabRoute">
		<el-tab-pane label="Event Info" name="event-info">
		  <form v-loading="formLoading">
			<div class="row">
			  <div class="col-md-12">
				<fg-input type="text"
						  label="Title"
						  placeholder="Title"
						  v-model="form.title">
				</fg-input>
			  </div>
			</div>
			<div class="row">
			  <div class="col-md-12">
				<fg-input type="text"
						  label="Location"
						  placeholder="Location"
						  v-model="form.location">
				</fg-input>
			  </div>
			</div>
			<div class="row">
				<div class="col-md-12">
					<label>Date/Time</label>
					<el-date-picker
							v-model="dateTimePicker"
							type="datetimerange"
							placeholder="Datetime picker"
							format="MMM dd, yyyy @ hh:mm a">
						</el-date-picker>
				</div>
			</div>
			<div class="row">
			  <div class="col-md-12">
				<label>Description</label>
				<editor :api-key="tinymceApiKey" type="textarea"
						  :init="{menubar: false, branding: false}"
						  :rows="20"
						  placeholder="Description"
						  v-model="form.description"
						  class="form-control w-100">
				</editor>
			  </div>
			</div>
			<div class="clearfix"></div>
		  </form>
		</el-tab-pane>
		<el-tab-pane label="RSVP" name="event-rsvp">
			<div class="row" v-if="event.rsvpList">
			  <div class="col-md-12">
				<label>Members</label>
				<l-button style="float: right;" @click.prevent="downloadRSVP">Download CSV</l-button>
				<el-table stripe
						  style="width: 100%;"
						  :data="form.rsvpList"
						  border>
					<el-table-column min-width="100" label="Email" prop="owner.email" />
					<el-table-column min-width="100" label="First Name" prop="owner.firstName" />
					<el-table-column min-width="100" label="Last Name" prop="owner.lastName" />
					<el-table-column min-width="100" label="Notes" prop="notes" />
					<el-table-column min-width="100" label="Created">
						<template slot-scope="{ row }">
							{{ row.createdAt | moment("calendar") }}
						</template>
					</el-table-column>
					<el-table-column min-width="40" label="Checked In">
						<template slot-scope="{ row }">
							<l-switch v-model="row.checkedIn" @change="updateMemberCheckIn(row)" />
						</template>
					</el-table-column>
				</el-table>
			  </div>
			</div>
			<div class="row" v-if="event.guests">
			  <div class="col-md-12">
				<label>Guests</label>
				<el-table stripe
						  style="width: 100%;"
						  :data="form.guests"
						  border>
				  <el-table-column min-width="100" label="Guest Info">
					<template slot-scope="props">
					  {{ props.row }}
					</template>
				  </el-table-column> 
				  <el-table-column
					width="80"
					fixed="right">
					<template slot-scope="props">
					  <div class="btn-group">
					  <a v-tooltip.top-center="'Remove'" class="btn-danger btn-sm btn"
						 @click="editMember(props.$index, props.row)"><i
						class="fa fa-times"></i></a>
					  </div>
					</template>
				  </el-table-column>
				</el-table>
			  </div>
			</div>
		</el-tab-pane>
		<el-tab-pane label="Access" name="event-access">
			<div class="row">
			  <div class="col-md-12">
				<label>Allowed Groups</label>
				<el-table stripe
						  style="width: 100%;"
						  :data="form.allowedGroupsList"
						  border>
				  <el-table-column min-width="100" prop="name" label="Name" sortable />
				  <el-table-column
					width="80"
					fixed="right">
					<template slot-scope="props">
					  <div class="btn-group">
						  <a v-tooltip.top-center="'Edit'" class="btn-info btn-sm btn"
							 @click="editGroup(props.$index, props.row)"><i
							class="fa fa-edit"></i></a>
					  </div>
					</template>
				  </el-table-column>
				</el-table>
			  </div>
			</div>
			<div class="row">
			  <div class="col-md-12">
				<label>Allowed Members</label>
				<el-table stripe
						  style="width: 100%;"
						  :data="form.allowedUsersList"
						  border>
				  <el-table-column min-width="100" prop="firstName" label="First Name" sortable />
				  <el-table-column min-width="100" prop="lastName" label="Last Name" sortable />
				  <el-table-column
					width="120"
					fixed="right">
					<template slot-scope="props">
					  <div class="btn-group">
						  <a v-tooltip.top-center="'RSVP'" class="btn-success btn-sm btn"
							 @click="rsvpMember(props.$index, props.row)"><i
							class="fa fa-envelope"></i></a>
						  <a v-tooltip.top-center="'Edit'" class="btn-info btn-sm btn"
							 @click="editMember(props.$index, props.row)"><i
							class="fa fa-edit"></i></a>
					  </div>
					</template>
				  </el-table-column>
				</el-table>
			  </div>
			</div>
		</el-tab-pane>
	</el-tabs>
	<DangerZone class="text-center">
		<button
			class="btn btn-danger btn-outline btn-wd"
			@click.prevent="remove">
			<i class="fa fa-warning"></i> Delete Event
		</button>
	</DangerZone>
  </card>
</div>
</template>
<script>
	import Editor from '@tinymce/tinymce-vue';
	import moment from 'moment';
	import Swal from 'sweetalert2';
	import DangerZone from '@/components/DangerZone';
	import LAlert from '@/components/Alert';
	import { Button, DatePicker, Dialog, Select, Option, Table, TableColumn, Tabs, TabPane } from 'element-ui';
	import { Switch as LSwitch } from 'src/components/index'
	export default {
		props: ['eventId'],
		components: {
			editor: Editor,
			DangerZone,
			[Button.name]: Button,
			[DatePicker.name]: DatePicker,
			[Dialog.name]: Dialog,
			[Select.name]: Select,
			[Option.name]: Option,
			[Table.name]: Table,
			[TableColumn.name]: TableColumn,
			[Tabs.name]: Tabs,
			[TabPane.name]: TabPane,
			LSwitch
		},
		data () {
			return {
				activeTab: this.$route.params.activeTab || 'event-info',
				editorLoading: true,
				formLoading: false,
				eventTypes: {
					0: 'Public',
					1: 'Private',
					2: 'Secret'
				},
				dangerZone: false,
				dialogFormVisible: false,
				event: false,
				form: false,
				dateTimePicker: [],
				message: false,
			}
		},
		computed: {
			Event () {
				return this.$FeathersVuex.api.Event;
			},
			RSVP () {
				return this.$FeathersVuex.api.RSVP;
			},
			isDirty () {
				return JSON.stringify(this.event) !== JSON.stringify(this.form);
			}
		},
		methods: {
			downloadRSVP () {
				this.downloadCSVData(
					`${this.event.title.toLowerCase().replace(' ', '-').replace(/[^a-z-]/g,'')}_guest_list_${moment().format('YYYY-MM-DD')}.csv`,
					['Email', 'First Name', 'Last Name', 'Notes'],
					this.event.rsvpList.map(r => {
						return [r.owner.email, r.owner.firstName, r.owner.lastName, r.notes];
					})
				);
			},
			downloadCSVData (filename, columns, data) {
				let csv = `${columns.join(',')}\n`;
				data.forEach((row) => {
						csv += row.join(',');
						csv += "\n";
				});
			 
				const anchor = document.createElement('a');
				anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
				anchor.target = '_blank';
				anchor.download = filename;
				anchor.click();
			},
			async rsvpMember (index, row) {
				const rsvp = await (new this.RSVP({ eventId: this.eventId, ownerId: row._id })).create();
				this.$notify({
					title: 'RSVP Created!',
					icon: 'nc-icon nc-check-2',
					horizontalAlign: 'right',
					verticalAlign: 'bottom',
					type: 'success'
				});
			},
			editGroup (index, row) {
				this.$router.push({ name: 'groups', params: { groupId: row._id } });
			},
			editMember (index, row) {
				this.$router.push({ name: 'members-profile', params: { memberId: row._id } });
			},
			async updateMemberCheckIn (row) {
				const { _id, checkedIn } = row;
				await (new this.RSVP({ _id, checkedIn })).save();
				this.$notify({
					title: 'User Check In Updated!',
					icon: 'nc-icon nc-check-2',
					horizontalAlign: 'right',
					verticalAlign: 'bottom',
					type: 'success'
				});
			},
			updateTabRoute () {
				this.$router.push({ params: { activeTab: this.activeTab } });
			},
			save () {
				Swal.fire({
					title: 'Are you sure?',
					text: `Change ${this.event.title} will be visible once you save...`,
					type: 'warning',
					showCancelButton: true,
					confirmButtonClass: 'btn btn-success btn-fill',
					cancelButtonClass: 'btn btn-default btn-simple',
					confirmButtonText: 'Save ' + this.event.title,
					buttonsStyling: false
				}).then(async result => {
					if (result.isConfirmed) {
						this.formLoading = true;
						try {
							await this.form.save();
							this.form.commit();
							Swal.fire({
								title: 'Saved!',
								text: `${this.event.title} was updated`,
								type: 'success',
								confirmButtonClass: 'btn btn-success btn-fill',
								buttonsStyling: false
							})
						} catch(e) {
							Swal.fire({
								title: 'Error!',
								text: `Sorry we couldn't update ${this.event.title} right now. If the problem continues please contact an administrator.`,
								type: 'error',
								confirmButtonClass: 'btn btn-danger btn-fill',
								buttonsStyling: false,
								confirmButtonText: 'Dismiss'
							});
						}
						this.formLoading = false;
					}
				})
			},
			remove () {
				Swal.fire({
					title: 'Are you sure?',
					text: `${this.event.title} will not be recoverable. All RSVP's will be deleted as well.`,
					type: 'warning',
					cancelButtonClass: 'btn btn-default btn-simple',
					confirmButtonText: 'Delete ' + this.event.title,
					buttonsStyling: false
				}).then(async result => {
					if (result.isConfirmed) {
						try {
							await this.event.remove();
							Swal.fire({
								title: 'Deleted!',
								text: `${this.event.title} was deleted`,
								type: 'success',
								confirmButtonClass: 'btn btn-success btn-fill',
								buttonsStyling: false
							})
							this.$router.push('/events');
						} catch(e) {
							Swal.fire({
								title: 'Error!',
								text: `Sorry we couldn't delete ${this.event.firstName} right now. If the problem continues please contact an administrator.`,
								type: 'error',
								confirmButtonClass: 'btn btn-danger btn-fill',
								buttonsStyling: false,
								confirmButtonText: 'Dismiss'
							});
						}
					}
				})
			}
		},
		watch: {
			dateTimePicker (val) {
				this.form.start = val[0];
				this.form.end = val[1];
			}
		},
		async mounted () {
			this.event = await this.Event.get(this.eventId);
			this.form = this.event.clone();
			this.dateTimePicker = [this.form.start, this.form.end];
		}
	}

</script>
<style scoped>
.tox-notifications-container {
	display: none !important;
}

/deep/ .card-header {
	box-shadow: 0px 1px 5px rgba(0,0,0,.05);
}

/deep/ .el-tabs__header {
	position: sticky;
	top: 78px;
	background: #fff;
	z-index: 20;
}
</style>
